<template>
  <div id="defaultId1">
    <div id="cc18ed" class="udb507 u22483 pb20">
      <div id="g2ede8" class="operations-content">
        <!-- 'marketAnalysisratingDistribution' -->


        <div>
          <!-- <div id="ge6845" class="uc1096" label="" :isShowLabel="false">
          <div class="operations-box-left" style="margin-top: 10px">
            <h2 id="gf7b16" class="operations-head detail_title_20">Rating Analysis</h2>
            <div class="operations-box">
              <el-select popper-class="common_select" v-model="reationvalue" placeholder="" style="width: 200px"
                @change="reationvaluechange">
                <el-option v-for="(item, index) in reationsoptions" :key="index" :label="item.reportYear"
                  :value="item.reportYear">
                </el-option>
              </el-select>

            </div>
            <div class="operations-box">
              <div class="operations-center-left flex_center_between_box">
                <div class="operations-center-item" style="margin-left: 0px">

                  <h3 class="TopPorts">Continent</h3>
                  <h3 class="TopPorts">
                    <el-select popper-class="common_select" v-model="reationvalue1" placeholder=""
                      @change="reationvaluechange1" :disabled="reationvalue == ''">
                      <el-option v-for="(item, index) in reationsoptions1" :key="index" :label="item.continent"
                        :value="item.continentCode">
                      </el-option>
                    </el-select>
                  </h3>
                  <div class="line_step" v-for="(item, index) in reationsoptions1" :key="index">
                    <div class="txt flex_center_between_box">
                      <span :class="item.continentCode == reationvalue1 ? 'line_title blue_numon' : 'line_title'">{{
                        item.continentCode }}</span>
                      <span :class="item.continentCode == reationvalue1 ? 'blue_num blue_numon' : 'blue_num'">{{
                        item.totalCount | FilterNum
                      }}</span>
                    </div>
                    <el-progress :color="item.continentCode == reationvalue1 ? '#1290C9' : '#8497AB'" :show-text="false"
                      :percentage="getProgress(item)"></el-progress>
                  </div>
                </div>
                <div class="operations-center-item">
                  <h3 class="TopPorts">Country</h3>
                  <h3 class="TopPorts">
                    <el-select popper-class="common_select" v-model="reationvalue6"
                      :disabled="reationvalue1 == 'Country'" @change="reationvaluechange2" placeholder="">
                      <el-option v-for="(item, index) in reationsoptions6" :key="index" :label="item.country"
                        :value="item.countryCode">
                      </el-option>
                    </el-select>
                  </h3>
                  <div class="line_step" v-for="(item, index) in reationsoptions6" :key="index">
                    <div class="txt flex_center_between_box">
                      <span :class="item.countryCode == reationvalue6 ? 'line_title blue_numon' : 'line_title'">{{
                        item.country
                      }}</span>
                      <span :class="item.countryCode == reationvalue6 ? 'blue_num blue_numon' : 'blue_num'">{{
                        item.totalCount | FilterNum
                      }}</span>
                    </div>
                    <el-progress :color="item.countryCode == reationvalue6 ? '#1290C9' : '#8497AB'" :show-text="false"
                      :percentage="getProgress(item)"></el-progress>
                  </div>
                </div>
                <div class="operations-center-item">
                  <h3 class="TopPorts">Industry</h3>
                  <h3 class="TopPorts">
                    <el-select popper-class="common_select" v-model="reationvalue2" placeholder="Industry"
                      @change="reationvaluechange3" :disabled="reationvalue6 == ''">
                      <el-option v-for="(item, index) in reationsoptions2" :key="index" :label="item.industry"
                        :value="item.industryCode">
                      </el-option>
                    </el-select>
                  </h3>
                  <div class="line_step" v-for="(item, index) in reationsoptions2" :key="index">
                    <div class="txt flex_center_between_box">
                      <span :class="item.industryCode == reationvalue2 ? 'line_title blue_numon' : 'line_title'">{{
                        item.industry }}</span>
                      <span :class="item.industryCode == reationvalue2 ? 'blue_num blue_numon' : 'blue_num'">{{
                        item.count | FilterNum
                      }}</span>
                    </div>
                    <el-progress :color="item.industryCode == reationvalue2 ? '#1290C9' : '#8497AB'" :show-text="false"
                      :percentage="getProgress(item)"></el-progress>
                  </div>
                </div>
                <div class="operations-center-item">
                  <h3 class="TopPorts">Co. Age</h3>
                  <h3 class="TopPorts">
                    <el-select popper-class="common_select" v-model="reationvalue3" @change="reationvaluechange4"
                      placeholder="">
                      <el-option v-for="(item, index) in reationsoptions3" :key="index" :label="item.companyYears"
                        :value="item.companyYearsCode">
                      </el-option>
                    </el-select>
                  </h3>
                  <div class="line_step" v-for="(item, index) in reationsoptions3" :key="index">
                    <div class="txt flex_center_between_box">
                      <span :class="item.companyYearsCode == reationvalue3 ? 'line_title blue_numon' : 'line_title'">{{
                        item.companyYears }}</span>
                      <span :class="item.companyYearsCode == reationvalue3 ? 'blue_num blue_numon' : 'blue_num'">{{
                        item.count | FilterNum
                      }}</span>
                    </div>
                    <el-progress :color="item.companyYearsCode == reationvalue3 ? '#1290C9' : '#8497AB'"
                      :show-text="false" :percentage="getProgress(item)"></el-progress>
                  </div>
                </div>
                <div class="operations-center-item">
                  <h3 class="TopPorts">Co. Type</h3>
                  <h3 class="TopPorts">
                    <el-select popper-class="common_select" v-model="reationvalue4" :disabled="reationvalue3 == ''"
                      @change="reationvaluechange5" placeholder="">
                      <el-option v-for="(item, index) in reationsoptions4" :key="index" :label="item.companyNature"
                        :value="item.companyNatureCode">
                      </el-option>
                    </el-select>
                  </h3>
                  <div class="line_step" v-for="(item, index) in reationsoptions4" :key="index">
                    <div class="txt flex_center_between_box">
                      <span :class="item.companyNatureCode == reationvalue4 ? 'line_title blue_numon' : 'line_title'">{{
                        item.companyNature }}</span>
                      <span :class="item.companyNatureCode == reationvalue4 ? 'blue_num blue_numon' : 'blue_num'">{{
                        item.count | FilterNum
                      }}</span>
                    </div>
                    <el-progress :color="item.companyNatureCode == reationvalue4 ? '#1290C9' : '#8497AB'"
                      :show-text="false" :percentage="getProgress(item)"></el-progress>
                  </div>
                </div>
                <div class="operations-center-item">
                  <h3 class="TopPorts">Co. Size</h3>
                  <h3 class="TopPorts">
                    <el-select popper-class="common_select" v-model="reationvalue5" placeholder="Company Size"
                      :disabled="reationvalue4 == ''" @change="reationvaluechange6">
                      <el-option v-for="(item, index) in reationsoptions5" :key="index" :label="item.companySize"
                        :value="item.companySizeCode">
                      </el-option>
                    </el-select>
                  </h3>
                  <div class="line_step" v-for="(item, index) in reationsoptions5" :key="index">
                    <div class="txt flex_center_between_box">
                      <span :class="item.companySizeCode == reationvalue5 ? 'line_title blue_numon' : 'line_title'">{{
                        item.companySize }}</span>
                      <span :class="item.companySizeCode == reationvalue5 ? 'blue_num blue_numon' : 'blue_num'">{{
                        item.count | FilterNum
                      }}</span>
                    </div>
                    <el-progress :color="item.companySizeCode == reationvalue5 ? '#1290C9' : '#8497AB'"
                      :show-text="false" :percentage="getProgress(item)"></el-progress>
                  </div>
                </div>
              </div>
              <div  class="operations-center-right  maxHeight" style="width: 100%;">
                <el-table :data="tableDatatwo" style="width: 100%" row-class-name="highlight-row"
                  :cell-class-name="cellClassName" empty-text="No Data" class=" table_height_30 table_font_size_14 table_contentHeight_40">
                  <el-table-column label="" prop="" style="height: 58px">
                    <template slot-scope="scope">
                      <span class="table-content">{{ scope.row.valueName }}</span>
                    </template>
</el-table-column>

<el-table-column label="Rating" prop="" style="height: 58px" align="right">
  <template slot-scope="scope">
                      <span class="table-content">{{ scope.row.rating }}</span>
                    </template>
</el-table-column>
<el-table-column label="No. of Co." prop="" style="height: 58px" align="right">
  <template slot-scope="scope">
                      <span class="table-content">{{ scope.row.numberOfItems }}</span>
                    </template>
</el-table-column>
<el-table-column label="Percentile" align="right" prop="Proportion">
  <template slot-scope="scope">
                      <span class="table-content">{{
                        scope.row.proportion
                      }}</span>
                    </template>
</el-table-column>
</el-table>
</div>
</div>
</div>
</div> -->

          <div class="operations-box" style="margin-top: 10px">
            <div id="ge6845" label="" :isShowLabel="false" style="margin-right: 10px; width: 45%">
              <div style="width:100%;height:100%">
                <div class="operations-box-left">
                  <div style="width:100% ;height:100%">
                    <h2 id="gf7b16" class="operations-head detail_title_20">
                      Attribute Comparison
                      <span class="ViewMore contclass">
                        <div :class="iconactive2 == 'table' ? 'svgbg svgclass' : 'svgclass'
                          ">
                          <svg t="1718592308727" v-if="iconactive2 == 'table'" class="icon" viewBox="0 0 1024 1024"
                            version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8137" width="2.2857rem"
                            height="2.2857rem">
                            <path
                              d="M928 938.666667H96a53.393333 53.393333 0 0 1-53.333333-53.333334V181.333333a53.393333 53.393333 0 0 1 53.333333-53.333333h832a53.393333 53.393333 0 0 1 53.333333 53.333333v704a53.393333 53.393333 0 0 1-53.333333 53.333334z m-245.333333-42.666667h245.333333a10.666667 10.666667 0 0 0 10.666667-10.666667v-202.666666h-256z m-298.666667 0h256v-213.333333H384z m-298.666667-213.333333v202.666666a10.666667 10.666667 0 0 0 10.666667 10.666667h245.333333v-213.333333z m597.333334-42.666667h256V426.666667h-256z m-298.666667 0h256V426.666667H384z m-298.666667 0h256V426.666667H85.333333z m853.333334-256V181.333333a10.666667 10.666667 0 0 0-10.666667-10.666666H96a10.666667 10.666667 0 0 0-10.666667 10.666666v202.666667z"
                              fill="#1290C9" p-id="8138"></path>
                          </svg>

                          <svg t="1718592110652" v-else @click="iconactive2 = 'table'" class="icon"
                            viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7626"
                            width="32" height="32">
                            <path
                              d="M928 938.666667H96a53.393333 53.393333 0 0 1-53.333333-53.333334V181.333333a53.393333 53.393333 0 0 1 53.333333-53.333333h832a53.393333 53.393333 0 0 1 53.333333 53.333333v704a53.393333 53.393333 0 0 1-53.333333 53.333334z m-245.333333-42.666667h245.333333a10.666667 10.666667 0 0 0 10.666667-10.666667v-202.666666h-256z m-298.666667 0h256v-213.333333H384z m-298.666667-213.333333v202.666666a10.666667 10.666667 0 0 0 10.666667 10.666667h245.333333v-213.333333z m597.333334-42.666667h256V426.666667h-256z m-298.666667 0h256V426.666667H384z m-298.666667 0h256V426.666667H85.333333z m853.333334-256V181.333333a10.666667 10.666667 0 0 0-10.666667-10.666666H96a10.666667 10.666667 0 0 0-10.666667 10.666666v202.666667z"
                              fill="#5C5C66" p-id="7627"></path>
                          </svg>
                        </div>
                        <div :class="iconactive2 == 'computer'
                          ? 'svgbg svgclass'
                          : 'svgclass'
                          ">
                          <svg t="1718592168330" v-if="iconactive2 == 'computer'" class="icon" viewBox="0 0 1214 1024"
                            version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7861" width="2.2857rem"
                            height="2.2857rem">
                            <path
                              d="M94.734629 819.2c-52.0192 0-94.354286-40.872229-94.354286-91.077486V341.284571c0-13.312 11.190857-24.107886 24.9856-24.107885 13.780114 0 22.908343 10.795886 22.908343 24.107885v386.823315c0 23.639771 21.986743 44.675657 46.460343 44.675657h1018.953142c24.488229 0 47.030857-21.035886 47.030858-44.675657V93.037714c0-23.6544-22.528-43.695543-47.030858-43.695543H94.734629C70.261029 49.3568 48.274286 69.397943 48.274286 93.037714v107.373715c0 13.297371-9.128229 24.093257-22.908343 24.093257-13.794743 0-25.000229-10.795886-25.000229-24.107886V93.037714C0.365714 42.832457 42.715429 1.989486 94.734629 1.989486h1018.953142c52.0192 0 94.339657 40.842971 94.339658 91.077485v635.070172c0 50.205257-42.320457 91.077486-94.339658 91.077486H94.734629z"
                              fill="#1290C9" p-id="7862"></path>
                            <path
                              d="M704.2048 965.485714H855.771429c12.112457 0 21.694171 11.264 21.694171 23.376457 0 12.112457-9.581714 23.6544-21.694171 23.6544h-482.742858c-12.112457 0-22.191543-11.541943-22.191542-23.6544 0-12.127086 10.079086-23.376457 22.191542-23.376457h151.581258V819.2H570.514286v146.285714h87.771428V819.2h45.919086v146.285714zM177.678629 620.163657h44.426971V461.531429h-44.426971zM296.1408 620.163657h44.412343V389.412571h-44.412343zM429.407086 620.163657h44.412343V317.293714h-44.412343zM236.909714 187.4944c0 15.915886-13.268114 28.847543-29.608228 28.847543-16.384 0-29.622857-12.931657-29.622857-28.847543 0-15.945143 13.253486-28.847543 29.622857-28.847543 16.340114 0 29.608229 12.9024 29.608228 28.847543M325.749029 187.4944c0 15.915886-13.268114 28.847543-29.608229 28.847543s-29.608229-12.931657-29.608229-28.847543c0-15.945143 13.268114-28.847543 29.608229-28.847543s29.608229 12.9024 29.608229 28.847543M714.591086 420.322743h148.728685l112.347429-93.432686c-26.814171-30.939429-68.183771-52.077714-112.347429-52.077714-79.491657 0-146.666057 66.530743-148.728685 145.5104zM820.662857 568.188343l21.869714-113.2544h-126.098285c16.310857 53.642971 51.053714 91.399314 104.228571 113.2544z m174.474972-210.549029l-91.428572 77.297372 91.428572 54.535314C1004.251429 470.381714 1009.371429 446.800457 1009.371429 424.228571c0-23.2448-4.593371-47.074743-14.2336-66.589257zM877.714286 462.760229l-20.289829 110.270171c1.594514 0.043886 3.189029 0.073143 4.783543 0.073143 44.909714 0 87.815314-20.904229 114.644114-52.736L877.714286 462.760229zM863.085714 614.4c-105.033143 0-190.171429-85.138286-190.171428-190.171429s85.138286-190.171429 190.171428-190.171428 190.171429 85.138286 190.171429 190.171428-85.138286 190.171429-190.171429 190.171429z"
                              fill="#1290C9" p-id="7863"></path>
                          </svg>

                          <svg t="1718592454748" @click="iconactive2 = 'computer'" v-else class="icon"
                            viewBox="0 0 1214 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8412"
                            width="2.2857rem" height="2.2857rem">
                            <path
                              d="M94.734629 819.2c-52.0192 0-94.354286-40.872229-94.354286-91.077486V341.284571c0-13.312 11.190857-24.107886 24.9856-24.107885 13.780114 0 22.908343 10.795886 22.908343 24.107885v386.823315c0 23.639771 21.986743 44.675657 46.460343 44.675657h1018.953142c24.488229 0 47.030857-21.035886 47.030858-44.675657V93.037714c0-23.6544-22.528-43.695543-47.030858-43.695543H94.734629C70.261029 49.3568 48.274286 69.397943 48.274286 93.037714v107.373715c0 13.297371-9.128229 24.093257-22.908343 24.093257-13.794743 0-25.000229-10.795886-25.000229-24.107886V93.037714C0.365714 42.832457 42.715429 1.989486 94.734629 1.989486h1018.953142c52.0192 0 94.339657 40.842971 94.339658 91.077485v635.070172c0 50.205257-42.320457 91.077486-94.339658 91.077486H94.734629z"
                              fill="#000000" p-id="8413"></path>
                            <path
                              d="M704.2048 965.485714H855.771429c12.112457 0 21.694171 11.264 21.694171 23.376457 0 12.112457-9.581714 23.6544-21.694171 23.6544h-482.742858c-12.112457 0-22.191543-11.541943-22.191542-23.6544 0-12.127086 10.079086-23.376457 22.191542-23.376457h151.581258V819.2H570.514286v146.285714h87.771428V819.2h45.919086v146.285714zM177.678629 620.163657h44.426971V461.531429h-44.426971zM296.1408 620.163657h44.412343V389.412571h-44.412343zM429.407086 620.163657h44.412343V317.293714h-44.412343zM236.909714 187.4944c0 15.915886-13.268114 28.847543-29.608228 28.847543-16.384 0-29.622857-12.931657-29.622857-28.847543 0-15.945143 13.253486-28.847543 29.622857-28.847543 16.340114 0 29.608229 12.9024 29.608228 28.847543M325.749029 187.4944c0 15.915886-13.268114 28.847543-29.608229 28.847543s-29.608229-12.931657-29.608229-28.847543c0-15.945143 13.268114-28.847543 29.608229-28.847543s29.608229 12.9024 29.608229 28.847543M714.591086 420.322743h148.728685l112.347429-93.432686c-26.814171-30.939429-68.183771-52.077714-112.347429-52.077714-79.491657 0-146.666057 66.530743-148.728685 145.5104zM820.662857 568.188343l21.869714-113.2544h-126.098285c16.310857 53.642971 51.053714 91.399314 104.228571 113.2544z m174.474972-210.549029l-91.428572 77.297372 91.428572 54.535314C1004.251429 470.381714 1009.371429 446.800457 1009.371429 424.228571c0-23.2448-4.593371-47.074743-14.2336-66.589257zM877.714286 462.760229l-20.289829 110.270171c1.594514 0.043886 3.189029 0.073143 4.783543 0.073143 44.909714 0 87.815314-20.904229 114.644114-52.736L877.714286 462.760229zM863.085714 614.4c-105.033143 0-190.171429-85.138286-190.171428-190.171429s85.138286-190.171429 190.171428-190.171428 190.171429 85.138286 190.171429 190.171428-85.138286 190.171429-190.171429 190.171429z"
                              fill="#000000" p-id="8414"></path>
                          </svg>
                        </div>
                      </span>
                    </h2>
                    <div class="flex_center_between_box" style="justify-content: start;">
                      <el-select popper-class="common_select" v-model="PerformanceComparisvalueone" filterable
                        ref="upSelect3" v-click-outside="clickOutSide3" placeholder="Select a Year"
                        @change="PerformanceComparisvalueonechange" style="width: 280px; font-size: 20px">
                        <el-option v-for="(item, index) in reationsoptions" :key="index" :label="item.reportYear"
                          :value="item.reportYear">
                        </el-option>
                      </el-select>
                      <el-select popper-class="common_select" v-if="iconactive2 == 'computer'" ref="upSelect4"
                        v-click-outside="clickOutSide4" v-model="Performancevalueone" filterable
                        style="margin-left: 20px; width:310px; font-size: 20px" placeholder="Select a type"
                        @change="SelectPerformanceComparisTwo">
                        <el-option v-for="(item, index) in PerformanceComparis" :key="index" :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                    <div v-show="iconactive2 == 'table'" class="Attribute-left">
                      <el-table :data="AttributeData" style="width: 100%" row-class-name="highlight-row"
                        :cell-class-name="cellClassName">
                        <el-table-column label="" prop="attributeName" style="height: 58px" width="130" align="left">
                          <template slot-scope="scope">
                            <span class="table-content">{{
                              scope.row.attributeName
                              }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column label="Revenue" align="center" prop="turnover" width="130"
                          show-overflow-tooltip>
                          <template slot-scope="scope">
                            <span class="table-content">{{
                              formatNumber(scope.row.turnover)
                              }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column label="Net Earnings" align="center" prop="NetPprofit" show-overflow-tooltip>
                          <template slot-scope="scope">
                            <span class="table-content">{{
                              formatNumber(scope.row.netProfit)
                              }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column label="Net Assets" align="center" prop="NetAssets" show-overflow-tooltip>
                          <template slot-scope="scope">
                            <span class="table-content">{{
                              formatNumber(scope.row.netAssets)
                              }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column label="Total  Assets" align="center" prop="TotalAssets" show-overflow-tooltip>
                          <template slot-scope="scope">
                            <span class="table-content">{{
                              formatNumber(scope.row.totalAssets)
                              }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column label="Employees" align="right" prop="Employee" width="140"
                          show-overflow-tooltip>
                          <template slot-scope="scope">
                            <span class="table-content">{{
                              formatNumber(scope.row.employee)
                              }}</span>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                    <div v-show="iconactive2 == 'computer'" ref="Attributechart" style="
                      height: 760px;
                      margin-left: 20px;
                      margin-top: 20px;
                    "></div>
                  </div>
                </div>
              </div>
            </div>
            <div id="ge6845" class="uc1096" label="" :isShowLabel="false" style="flex: 1">
              <div class="operations-box-right operations-box-left" style="width: 100%">
                <div style="width:100%">
                  <h2 id="gf7b16" class="operations-head detail_title_20">
                    Performance Comparis
                    <span class="ViewMore contclass">
                      <div :class="iconactive == 'table' ? 'svgbg svgclass' : 'svgclass'
                        ">
                        <svg t="1718592308727" v-if="iconactive == 'table'" class="icon" viewBox="0 0 1024 1024"
                          version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8137" width="2.2857rem"
                          height="2.2857rem">
                          <path
                            d="M928 938.666667H96a53.393333 53.393333 0 0 1-53.333333-53.333334V181.333333a53.393333 53.393333 0 0 1 53.333333-53.333333h832a53.393333 53.393333 0 0 1 53.333333 53.333333v704a53.393333 53.393333 0 0 1-53.333333 53.333334z m-245.333333-42.666667h245.333333a10.666667 10.666667 0 0 0 10.666667-10.666667v-202.666666h-256z m-298.666667 0h256v-213.333333H384z m-298.666667-213.333333v202.666666a10.666667 10.666667 0 0 0 10.666667 10.666667h245.333333v-213.333333z m597.333334-42.666667h256V426.666667h-256z m-298.666667 0h256V426.666667H384z m-298.666667 0h256V426.666667H85.333333z m853.333334-256V181.333333a10.666667 10.666667 0 0 0-10.666667-10.666666H96a10.666667 10.666667 0 0 0-10.666667 10.666666v202.666667z"
                            fill="#1290C9" p-id="8138"></path>
                        </svg>

                        <svg t="1718592110652" v-else @click="iconactive = 'table'" class="icon" viewBox="0 0 1024 1024"
                          version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7626" width="2.2857rem"
                          height="2.2857rem">
                          <path
                            d="M928 938.666667H96a53.393333 53.393333 0 0 1-53.333333-53.333334V181.333333a53.393333 53.393333 0 0 1 53.333333-53.333333h832a53.393333 53.393333 0 0 1 53.333333 53.333333v704a53.393333 53.393333 0 0 1-53.333333 53.333334z m-245.333333-42.666667h245.333333a10.666667 10.666667 0 0 0 10.666667-10.666667v-202.666666h-256z m-298.666667 0h256v-213.333333H384z m-298.666667-213.333333v202.666666a10.666667 10.666667 0 0 0 10.666667 10.666667h245.333333v-213.333333z m597.333334-42.666667h256V426.666667h-256z m-298.666667 0h256V426.666667H384z m-298.666667 0h256V426.666667H85.333333z m853.333334-256V181.333333a10.666667 10.666667 0 0 0-10.666667-10.666666H96a10.666667 10.666667 0 0 0-10.666667 10.666666v202.666667z"
                            fill="#5C5C66" p-id="7627"></path>
                        </svg>
                      </div>
                      <div :class="iconactive == 'computer' ? 'svgbg svgclass' : 'svgclass'
                        ">
                        <svg t="1718592168330" v-if="iconactive == 'computer'" class="icon" viewBox="0 0 1214 1024"
                          version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7861" width="2.2857rem"
                          height="2.2857rem">
                          <path
                            d="M94.734629 819.2c-52.0192 0-94.354286-40.872229-94.354286-91.077486V341.284571c0-13.312 11.190857-24.107886 24.9856-24.107885 13.780114 0 22.908343 10.795886 22.908343 24.107885v386.823315c0 23.639771 21.986743 44.675657 46.460343 44.675657h1018.953142c24.488229 0 47.030857-21.035886 47.030858-44.675657V93.037714c0-23.6544-22.528-43.695543-47.030858-43.695543H94.734629C70.261029 49.3568 48.274286 69.397943 48.274286 93.037714v107.373715c0 13.297371-9.128229 24.093257-22.908343 24.093257-13.794743 0-25.000229-10.795886-25.000229-24.107886V93.037714C0.365714 42.832457 42.715429 1.989486 94.734629 1.989486h1018.953142c52.0192 0 94.339657 40.842971 94.339658 91.077485v635.070172c0 50.205257-42.320457 91.077486-94.339658 91.077486H94.734629z"
                            fill="#1290C9" p-id="7862"></path>
                          <path
                            d="M704.2048 965.485714H855.771429c12.112457 0 21.694171 11.264 21.694171 23.376457 0 12.112457-9.581714 23.6544-21.694171 23.6544h-482.742858c-12.112457 0-22.191543-11.541943-22.191542-23.6544 0-12.127086 10.079086-23.376457 22.191542-23.376457h151.581258V819.2H570.514286v146.285714h87.771428V819.2h45.919086v146.285714zM177.678629 620.163657h44.426971V461.531429h-44.426971zM296.1408 620.163657h44.412343V389.412571h-44.412343zM429.407086 620.163657h44.412343V317.293714h-44.412343zM236.909714 187.4944c0 15.915886-13.268114 28.847543-29.608228 28.847543-16.384 0-29.622857-12.931657-29.622857-28.847543 0-15.945143 13.253486-28.847543 29.622857-28.847543 16.340114 0 29.608229 12.9024 29.608228 28.847543M325.749029 187.4944c0 15.915886-13.268114 28.847543-29.608229 28.847543s-29.608229-12.931657-29.608229-28.847543c0-15.945143 13.268114-28.847543 29.608229-28.847543s29.608229 12.9024 29.608229 28.847543M714.591086 420.322743h148.728685l112.347429-93.432686c-26.814171-30.939429-68.183771-52.077714-112.347429-52.077714-79.491657 0-146.666057 66.530743-148.728685 145.5104zM820.662857 568.188343l21.869714-113.2544h-126.098285c16.310857 53.642971 51.053714 91.399314 104.228571 113.2544z m174.474972-210.549029l-91.428572 77.297372 91.428572 54.535314C1004.251429 470.381714 1009.371429 446.800457 1009.371429 424.228571c0-23.2448-4.593371-47.074743-14.2336-66.589257zM877.714286 462.760229l-20.289829 110.270171c1.594514 0.043886 3.189029 0.073143 4.783543 0.073143 44.909714 0 87.815314-20.904229 114.644114-52.736L877.714286 462.760229zM863.085714 614.4c-105.033143 0-190.171429-85.138286-190.171428-190.171429s85.138286-190.171429 190.171428-190.171428 190.171429 85.138286 190.171429 190.171428-85.138286 190.171429-190.171429 190.171429z"
                            fill="#1290C9" p-id="7863"></path>
                        </svg>

                        <svg t="1718592454748" @click="zhuhuangcehartsi()" v-else class="icon" viewBox="0 0 1214 1024"
                          version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8412" width="2.2857rem"
                          height="2.2857rem">
                          <path
                            d="M94.734629 819.2c-52.0192 0-94.354286-40.872229-94.354286-91.077486V341.284571c0-13.312 11.190857-24.107886 24.9856-24.107885 13.780114 0 22.908343 10.795886 22.908343 24.107885v386.823315c0 23.639771 21.986743 44.675657 46.460343 44.675657h1018.953142c24.488229 0 47.030857-21.035886 47.030858-44.675657V93.037714c0-23.6544-22.528-43.695543-47.030858-43.695543H94.734629C70.261029 49.3568 48.274286 69.397943 48.274286 93.037714v107.373715c0 13.297371-9.128229 24.093257-22.908343 24.093257-13.794743 0-25.000229-10.795886-25.000229-24.107886V93.037714C0.365714 42.832457 42.715429 1.989486 94.734629 1.989486h1018.953142c52.0192 0 94.339657 40.842971 94.339658 91.077485v635.070172c0 50.205257-42.320457 91.077486-94.339658 91.077486H94.734629z"
                            fill="#000000" p-id="8413"></path>
                          <path
                            d="M704.2048 965.485714H855.771429c12.112457 0 21.694171 11.264 21.694171 23.376457 0 12.112457-9.581714 23.6544-21.694171 23.6544h-482.742858c-12.112457 0-22.191543-11.541943-22.191542-23.6544 0-12.127086 10.079086-23.376457 22.191542-23.376457h151.581258V819.2H570.514286v146.285714h87.771428V819.2h45.919086v146.285714zM177.678629 620.163657h44.426971V461.531429h-44.426971zM296.1408 620.163657h44.412343V389.412571h-44.412343zM429.407086 620.163657h44.412343V317.293714h-44.412343zM236.909714 187.4944c0 15.915886-13.268114 28.847543-29.608228 28.847543-16.384 0-29.622857-12.931657-29.622857-28.847543 0-15.945143 13.253486-28.847543 29.622857-28.847543 16.340114 0 29.608229 12.9024 29.608228 28.847543M325.749029 187.4944c0 15.915886-13.268114 28.847543-29.608229 28.847543s-29.608229-12.931657-29.608229-28.847543c0-15.945143 13.268114-28.847543 29.608229-28.847543s29.608229 12.9024 29.608229 28.847543M714.591086 420.322743h148.728685l112.347429-93.432686c-26.814171-30.939429-68.183771-52.077714-112.347429-52.077714-79.491657 0-146.666057 66.530743-148.728685 145.5104zM820.662857 568.188343l21.869714-113.2544h-126.098285c16.310857 53.642971 51.053714 91.399314 104.228571 113.2544z m174.474972-210.549029l-91.428572 77.297372 91.428572 54.535314C1004.251429 470.381714 1009.371429 446.800457 1009.371429 424.228571c0-23.2448-4.593371-47.074743-14.2336-66.589257zM877.714286 462.760229l-20.289829 110.270171c1.594514 0.043886 3.189029 0.073143 4.783543 0.073143 44.909714 0 87.815314-20.904229 114.644114-52.736L877.714286 462.760229zM863.085714 614.4c-105.033143 0-190.171429-85.138286-190.171428-190.171429s85.138286-190.171429 190.171428-190.171428 190.171429 85.138286 190.171429 190.171428-85.138286 190.171429-190.171429 190.171429z"
                            fill="#000000" p-id="8414"></path>
                        </svg>
                      </div>
                    </span>
                  </h2>
                  <div class="flex_center_between_box" style="    justify-content: start;">
                    <el-select popper-class="common_select" v-model="PerformanceComparisvalue" filterable
                      ref="upSelecttwo" v-click-outside="clickOutSidetwo" placeholder="Select a Year"
                      @change="PerformanceComparisvaluechange()" style="width: 240px; font-size: 20px">
                      <el-option v-for="(item, index) in reationsoptions" :key="index" :label="item.reportYear"
                        :value="item.reportYear">
                      </el-option>
                    </el-select>

                    <el-select ref="upSelect" v-click-outside="clickOutSide" popper-class="common_select"
                      v-model="Performancevalue" filterable style="margin-left: 20px; width:310px; font-size: 20px"
                      placeholder="Select a type" @change="SelectPerformanceComparis">
                      <el-option v-for="(item, index) in PerformanceComparis" :key="index" :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <div v-show="iconactive == 'table'" class="fivetable">
                    <el-table empty-text="No Data" :data="ProfitabilityRatio" style="width: 100%" :key="Math.round()"
                      row-class-name="highlight-row" :cell-class-name="cellClassName">
                      <el-table-column label="Profitability Ratios" prop="" width="160" align="left">
                        <template slot-scope="scope">
                          <span>{{ scope.row.name }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column label="Gross margin  (%)" align="center" prop="grossProfitMargin">
                        <template slot-scope="scope">
                          <span>{{ scope.row.grossProfitMargin }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column label="Return on assets  (%)" align="center" prop="returnOnTotalAssets">
                        <template slot-scope="scope">
                          <span>{{ scope.row.returnOnTotalAssets }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column label="Return on equity  (%)" align="center" prop="returnOnEquity">
                        <template slot-scope="scope">
                          <span class="table-content">{{
                            scope.row.returnOnEquity
                            }}</span>
                        </template>
                      </el-table-column>
                    </el-table>
                    <!--  -->
                    <el-table empty-text="No Data" :data="ProfitabilityRatio" style="width: 100%" :key="Math.round()"
                      row-class-name="highlight-row" :cell-class-name="cellClassName">
                      <el-table-column label="Operating Ratios" prop="name" width="160" align="left">
                        <template slot-scope="scope">
                          <span>{{ scope.row.name }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column label="Stock turnover  (x)" align="center" prop="inventoryTurnoverRate">
                        <template slot-scope="scope">
                          <span>{{ scope.row.inventoryTurnoverRate }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column label="Assets turnover  (x)" align="center" prop="currentAssetTurnover">
                        <template slot-scope="scope">
                          <span>{{ scope.row.currentAssetTurnover }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column label="Collection period  (days)" align="center"
                        prop="accountsReceivableTurnoverRate">
                        <template slot-scope="scope">
                          <span>{{ scope.row.accountsReceivableTurnoverRate }}</span>
                        </template>
                      </el-table-column>
                    </el-table>
                    <el-table empty-text="No Data" :data="ProfitabilityRatio" style="width: 100%" :key="Math.round()"
                      row-class-name="highlight-row" :cell-class-name="cellClassName">
                      <el-table-column label="Growth Ratios" prop="name" width="160" align="left">
                        <template slot-scope="scope">
                          <span>{{ scope.row.name }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column label="Total assets growth rate  (%)" align="center" prop="totalAssetGrowthRate">
                        <template slot-scope="scope">
                          <span>{{ scope.row.totalAssetGrowthRate }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column label="Sales growth rate  (%)" align="center" prop="revenueGrowthRate">
                        <template slot-scope="scope">
                          <span>{{ scope.row.revenueGrowthRate }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column label="Net profit growth rate  (%)" align="center"
                        prop="operatingProfitGrowthRate">
                        <template slot-scope="scope">
                          <span>{{ scope.row.operatingProfitGrowthRate }}</span>
                        </template>
                      </el-table-column>
                    </el-table>
                    <el-table empty-text="No Data" :data="ProfitabilityRatio" style="width: 100%" :key="Math.round()"
                      row-class-name="highlight-row" :cell-class-name="cellClassName">
                      <el-table-column label="Structural Ratios" prop="name" width="160" align="left">
                        <template slot-scope="scope">
                          <span>{{ scope.row.name }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column label="Debt  ratio  (%)" align="center" prop="assetLiabilityRatio">
                        <template slot-scope="scope">
                          <span>{{ scope.row.assetLiabilityRatio }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column label="Quick ratio  (x)" align="center" prop="quickRatio">
                        <template slot-scope="scope">
                          <span>{{ scope.row.quickRatio }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column label="Current ratio  (x)" align="center" prop="currentRatio">
                        <template slot-scope="scope">
                          <span class="table-content">{{
                            scope.row.currentRatio
                            }}</span>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                  <div v-if="iconactive == 'computer'" class="flex-warp">
                    <div style="width: 49%">
                      <div class="flex-warp-item" ref="zhuzhuangtuschart" style="
                      width: 100%;
                      height: 300px;
                      padding-left: 10px;
                      padding-top: 10px;
                    "></div>
                      <div class="flex-warp-item" ref="zhuzhuangtuschart1" style="
                      width: 100%;
                      height: 400px;
                      padding-left: 10px;
                      padding-top: 10px;
                      margin-top:10px
                    "></div>

                      <div></div>
                    </div>
                    <div style="width: 49%">
                      <div class="flex-warp-item" ref="zhuzhuangtuschart2" style="
                      width: 100%;
                      height: 300px;
                      padding-left: 10px;
                      padding-top: 10px;
                    "></div>
                      <div class="flex-warp-item" ref="zhuzhuangtuschart3" style="
                      width: 100%;
                      height: 400px;
                      padding-left: 10px;
                      padding-top: 10px;
                         margin-top:10px
                    "></div>

                      <div></div>
                    </div>
                  </div>
                </div>
                <!-- <div v-show="iconactive == 'computer'">
                <el-table
                  :data="tableData"
                  style="width: 100%"
                  row-class-name="highlight-row"
                  :cell-class-name="cellClassName"
                >
                  <el-table-column
                    label=""
                    prop="Address"
                    style="height: 58px"
                    width="250px"
                  >
                    <template slot-scope="scope">
                      <span class="table-content">{{ scope.row.Address }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="SubiectCompany"
                    align="center"
                    prop="SubiectCompany"
                    width="200"
                  >
                    <template slot-scope="scope">
                      <span class="table-content">{{
                        scope.row.SubiectCompany
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="SameIndustry"
                    align="right"
                    prop="SameIndustry"
                    width="160"
                  >
                    <template slot-scope="scope">
                      <span class="table-content">{{
                        scope.row.SameIndustry
                      }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div
                v-show="iconactive == 'table'"
                ref="IndustryStatisticschart"
                style="width: 750px; height: 530px; margin-left: 10px"
              ></div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- <div  >
        <div class="operations-box" style="margin-top: 10px" v-if="RatingDistributionData[0]">
          <div id="ge6845" label="" :isShowLabel="false" style="width:100% ;height:100%">
            <div style="width:100%;height:100%">
              <div class="operations-box-left">
                <div style="width:100% ;height:100%">
                  <h2 id="gf7b16" class="operations-head detail_title_20">
                    Rating Distribution
                    <span class="ViewMore contclass">
                      <div :class="iconactive4 == 'table' ? 'svgbg svgclass' : 'svgclass'
                        ">
                        <svg t="1718592308727" v-if="iconactive4 == 'table'" class="icon" viewBox="0 0 1024 1024"
                          version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8137" width="2.2857rem" height="2.2857rem">
                          <path
                            d="M928 938.666667H96a53.393333 53.393333 0 0 1-53.333333-53.333334V181.333333a53.393333 53.393333 0 0 1 53.333333-53.333333h832a53.393333 53.393333 0 0 1 53.333333 53.333333v704a53.393333 53.393333 0 0 1-53.333333 53.333334z m-245.333333-42.666667h245.333333a10.666667 10.666667 0 0 0 10.666667-10.666667v-202.666666h-256z m-298.666667 0h256v-213.333333H384z m-298.666667-213.333333v202.666666a10.666667 10.666667 0 0 0 10.666667 10.666667h245.333333v-213.333333z m597.333334-42.666667h256V426.666667h-256z m-298.666667 0h256V426.666667H384z m-298.666667 0h256V426.666667H85.333333z m853.333334-256V181.333333a10.666667 10.666667 0 0 0-10.666667-10.666666H96a10.666667 10.666667 0 0 0-10.666667 10.666666v202.666667z"
                            fill="#1290C9" p-id="8138"></path>
                        </svg>

                        <svg t="1718592110652" v-else @click="iconactive4 = 'table'" class="icon"
                          viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7626"
                          width="2.2857rem" height="2.2857rem">
                          <path
                            d="M928 938.666667H96a53.393333 53.393333 0 0 1-53.333333-53.333334V181.333333a53.393333 53.393333 0 0 1 53.333333-53.333333h832a53.393333 53.393333 0 0 1 53.333333 53.333333v704a53.393333 53.393333 0 0 1-53.333333 53.333334z m-245.333333-42.666667h245.333333a10.666667 10.666667 0 0 0 10.666667-10.666667v-202.666666h-256z m-298.666667 0h256v-213.333333H384z m-298.666667-213.333333v202.666666a10.666667 10.666667 0 0 0 10.666667 10.666667h245.333333v-213.333333z m597.333334-42.666667h256V426.666667h-256z m-298.666667 0h256V426.666667H384z m-298.666667 0h256V426.666667H85.333333z m853.333334-256V181.333333a10.666667 10.666667 0 0 0-10.666667-10.666666H96a10.666667 10.666667 0 0 0-10.666667 10.666666v202.666667z"
                            fill="#5C5C66" p-id="7627"></path>
                        </svg>
                      </div>
                      <div :class="iconactive4 == 'computer'
                        ? 'svgbg svgclass'
                        : 'svgclass'
                        ">
                        <svg t="1718592168330" v-if="iconactive4 == 'computer'" class="icon" viewBox="0 0 1214 1024"
                          version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7861" width="2.2857rem" height="2.2857rem">
                          <path
                            d="M94.734629 819.2c-52.0192 0-94.354286-40.872229-94.354286-91.077486V341.284571c0-13.312 11.190857-24.107886 24.9856-24.107885 13.780114 0 22.908343 10.795886 22.908343 24.107885v386.823315c0 23.639771 21.986743 44.675657 46.460343 44.675657h1018.953142c24.488229 0 47.030857-21.035886 47.030858-44.675657V93.037714c0-23.6544-22.528-43.695543-47.030858-43.695543H94.734629C70.261029 49.3568 48.274286 69.397943 48.274286 93.037714v107.373715c0 13.297371-9.128229 24.093257-22.908343 24.093257-13.794743 0-25.000229-10.795886-25.000229-24.107886V93.037714C0.365714 42.832457 42.715429 1.989486 94.734629 1.989486h1018.953142c52.0192 0 94.339657 40.842971 94.339658 91.077485v635.070172c0 50.205257-42.320457 91.077486-94.339658 91.077486H94.734629z"
                            fill="#1290C9" p-id="7862"></path>
                          <path
                            d="M704.2048 965.485714H855.771429c12.112457 0 21.694171 11.264 21.694171 23.376457 0 12.112457-9.581714 23.6544-21.694171 23.6544h-482.742858c-12.112457 0-22.191543-11.541943-22.191542-23.6544 0-12.127086 10.079086-23.376457 22.191542-23.376457h151.581258V819.2H570.514286v146.285714h87.771428V819.2h45.919086v146.285714zM177.678629 620.163657h44.426971V461.531429h-44.426971zM296.1408 620.163657h44.412343V389.412571h-44.412343zM429.407086 620.163657h44.412343V317.293714h-44.412343zM236.909714 187.4944c0 15.915886-13.268114 28.847543-29.608228 28.847543-16.384 0-29.622857-12.931657-29.622857-28.847543 0-15.945143 13.253486-28.847543 29.622857-28.847543 16.340114 0 29.608229 12.9024 29.608228 28.847543M325.749029 187.4944c0 15.915886-13.268114 28.847543-29.608229 28.847543s-29.608229-12.931657-29.608229-28.847543c0-15.945143 13.268114-28.847543 29.608229-28.847543s29.608229 12.9024 29.608229 28.847543M714.591086 420.322743h148.728685l112.347429-93.432686c-26.814171-30.939429-68.183771-52.077714-112.347429-52.077714-79.491657 0-146.666057 66.530743-148.728685 145.5104zM820.662857 568.188343l21.869714-113.2544h-126.098285c16.310857 53.642971 51.053714 91.399314 104.228571 113.2544z m174.474972-210.549029l-91.428572 77.297372 91.428572 54.535314C1004.251429 470.381714 1009.371429 446.800457 1009.371429 424.228571c0-23.2448-4.593371-47.074743-14.2336-66.589257zM877.714286 462.760229l-20.289829 110.270171c1.594514 0.043886 3.189029 0.073143 4.783543 0.073143 44.909714 0 87.815314-20.904229 114.644114-52.736L877.714286 462.760229zM863.085714 614.4c-105.033143 0-190.171429-85.138286-190.171428-190.171429s85.138286-190.171429 190.171428-190.171428 190.171429 85.138286 190.171429 190.171428-85.138286 190.171429-190.171429 190.171429z"
                            fill="#1290C9" p-id="7863"></path>
                        </svg>

                        <svg t="1718592454748" @click="iconactive4 = 'computer'" v-else class="icon"
                          viewBox="0 0 1214 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8412"
                          width="2.2857rem" height="2.2857rem">
                          <path
                            d="M94.734629 819.2c-52.0192 0-94.354286-40.872229-94.354286-91.077486V341.284571c0-13.312 11.190857-24.107886 24.9856-24.107885 13.780114 0 22.908343 10.795886 22.908343 24.107885v386.823315c0 23.639771 21.986743 44.675657 46.460343 44.675657h1018.953142c24.488229 0 47.030857-21.035886 47.030858-44.675657V93.037714c0-23.6544-22.528-43.695543-47.030858-43.695543H94.734629C70.261029 49.3568 48.274286 69.397943 48.274286 93.037714v107.373715c0 13.297371-9.128229 24.093257-22.908343 24.093257-13.794743 0-25.000229-10.795886-25.000229-24.107886V93.037714C0.365714 42.832457 42.715429 1.989486 94.734629 1.989486h1018.953142c52.0192 0 94.339657 40.842971 94.339658 91.077485v635.070172c0 50.205257-42.320457 91.077486-94.339658 91.077486H94.734629z"
                            fill="#000000" p-id="8413"></path>
                          <path
                            d="M704.2048 965.485714H855.771429c12.112457 0 21.694171 11.264 21.694171 23.376457 0 12.112457-9.581714 23.6544-21.694171 23.6544h-482.742858c-12.112457 0-22.191543-11.541943-22.191542-23.6544 0-12.127086 10.079086-23.376457 22.191542-23.376457h151.581258V819.2H570.514286v146.285714h87.771428V819.2h45.919086v146.285714zM177.678629 620.163657h44.426971V461.531429h-44.426971zM296.1408 620.163657h44.412343V389.412571h-44.412343zM429.407086 620.163657h44.412343V317.293714h-44.412343zM236.909714 187.4944c0 15.915886-13.268114 28.847543-29.608228 28.847543-16.384 0-29.622857-12.931657-29.622857-28.847543 0-15.945143 13.253486-28.847543 29.622857-28.847543 16.340114 0 29.608229 12.9024 29.608228 28.847543M325.749029 187.4944c0 15.915886-13.268114 28.847543-29.608229 28.847543s-29.608229-12.931657-29.608229-28.847543c0-15.945143 13.268114-28.847543 29.608229-28.847543s29.608229 12.9024 29.608229 28.847543M714.591086 420.322743h148.728685l112.347429-93.432686c-26.814171-30.939429-68.183771-52.077714-112.347429-52.077714-79.491657 0-146.666057 66.530743-148.728685 145.5104zM820.662857 568.188343l21.869714-113.2544h-126.098285c16.310857 53.642971 51.053714 91.399314 104.228571 113.2544z m174.474972-210.549029l-91.428572 77.297372 91.428572 54.535314C1004.251429 470.381714 1009.371429 446.800457 1009.371429 424.228571c0-23.2448-4.593371-47.074743-14.2336-66.589257zM877.714286 462.760229l-20.289829 110.270171c1.594514 0.043886 3.189029 0.073143 4.783543 0.073143 44.909714 0 87.815314-20.904229 114.644114-52.736L877.714286 462.760229zM863.085714 614.4c-105.033143 0-190.171429-85.138286-190.171428-190.171429s85.138286-190.171429 190.171428-190.171428 190.171429 85.138286 190.171429 190.171428-85.138286 190.171429-190.171429 190.171429z"
                            fill="#000000" p-id="8414"></path>
                        </svg>
                      </div>
                    </span>
                  </h2>
                  <div class="flex_center_between_box" style="margin-bottom:20px">
                    <el-select popper-class="common_select" v-model="RatingDistributionyear" filterable
                      placeholder="Select a Year" @change="RatingDistributionyearchange()"
                      style="width: 240px; font-size: 20px">
                      <el-option v-for="(item, index) in reationsoptions" :key="index" :label="item.reportYear"
                        :value="item.reportYear">
                      </el-option>
                    </el-select>
                  </div>
                  <div v-show="iconactive4 == 'table'">
                    <el-table :data="RatingDistributionDatatable" style="width: 100%">
                      <el-table-column prop="rowName" label="" align="left" width="280">
                        <template slot-scope="scope">
                          <span class="table-content">{{ scope.row.rowName }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column prop="chinaBusinessServiceIndustry" label="China’s Business Service Industry"
                        align="center">
                        <template slot-scope="scope">
                          <span class="table-content">{{ scope.row.chinaBusinessServiceIndustry }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column prop="establishedFor3To5Years" label="Established for 3 to 5 Years"
                        align="center" width="350">
                        <template slot-scope="scope">
                          <span class="table-content">{{ scope.row.establishedFor3To5Years }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column prop="smallAndMediumSizedEnterprisesOfTheSameScale"
                        label="Small And Medium-Sized Enterprises of The Same Scale" align="center">
                        <template slot-scope="scope">
                          <span class="table-content">{{ scope.row.smallAndMediumSizedEnterprisesOfTheSameScale
                            }}</span>
                        </template>
                      </el-table-column>

                    </el-table>
                  </div>
                  <div class="flex_center_between_box" v-show="iconactive4 == 'computer'">
                    <div class="ecahrtstu" v-show="RatingDistributionData[0]">
                      <div class="table">
                        <span class="Create-titel">{{ RatingDistributionData[0]?.attributeName }}</span>
                        <div class="table_box">
                          <span class="aaa">High Risk</span>
                          <span class="bbb">Medium</span>
                          <span class="ccc">Moderate</span>
                          <span class="ddd">Average</span>
                          <span class="fff">Lower Than Avg.</span>
                          <span class="ggg">Low Risk</span>
                          <span class="hhh">Creditreform Rating</span>
                          <span class="jjj">Target Company</span>
                          <div class="echarts" ref="riskRef"></div>
                        </div>
                      </div>
   
                    </div>
                    <div class="ecahrtstu" v-show="RatingDistributionData[1]">
                      <div class="table">
                        <span class="Create-titel">{{ RatingDistributionData[1]?.attributeName }}</span>

                        <div class="table_box">

                          <span class="aaa">High Risk</span>
                          <span class="bbb">Medium</span>
                          <span class="ccc">Moderate</span>
                          <span class="ddd">Average</span>
                          <span class="fff">Lower Than Avg.</span>
                          <span class="ggg">Low Risk</span>
                          <span class="hhh">Creditreform Rating</span>
                          <span class="jjj">Target Company</span>
                          <div class="echarts" ref="riskRef1"></div>
                        </div>
                      </div>

                    </div>
                    <div class="ecahrtstu" v-show="RatingDistributionData[2]">
                      <div class="table">
                        <span class="Create-titel">{{ RatingDistributionData[2]?.attributeName }}</span>
                        <div class="table_box">
                          <span class="aaa">High Risk</span>
                          <span class="bbb">Medium</span>
                          <span class="ccc">Moderate</span>
                          <span class="ddd">Average</span>
                          <span class="fff">Lower Than Avg.</span>
                          <span class="ggg">Low Risk</span>
                          <span class="hhh">Creditreform Rating</span>
                          <span class="jjj">Target Company</span>
                          <div class="echarts" ref="riskRef2"></div>
                        </div>
                      </div>
          
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div> -->
      </div>
    </div>
    <ReLogin :reLoginPop="reLoginVis"></ReLogin>
  </div>
</template>
<script>
import {

  getRatingDistribution,
  getBlockListData,
  getAttrbuteComparison,
  getPerformanceComparison,
  getRatingAnalysisNumbyCondition,
  getratingAnalysisCurrent,
  getgetMarketAnalysisQuantity
} from "@/api/companyApi";
import topecharts from "./topecharts.vue";
import ReLogin from "../../../../components/ReLogin.vue";
export default {
  name: "",

  //   props: {},

  components: { topecharts, ReLogin },
  watch: {
    '$route'(to, from) {
      // 在路由变化时执行的代码

      if (to.name == 'marketAnalysisattributeComparison' || to.name == 'marketAnalysisratingAnalysis' || to.name == 'marketAnalysisratingDistribution') {

        this.Attributechart();

        this.$nextTick(() => {
          this.echartsBox.resize(); // 确保图表在可见时调整大小
          this.echartsBox1.resize()
          this.echartsBox2.resize()
          this.echartsBox3.resize()
        });

      }


    }
  },
  data() {
    return {
      reLoginVis: false,
      reationvalue: "",
      reationvalue1: "",
      reationvalue2: "",
      reationvalue3: "",
      reationvalue4: "",
      reationvalue5: "",
      reationvalue6: "",
      Continent: [],
      Country: [],
      Provinces: [],
      Years: [],
      Nature: [],
      Size: [],
      reationsoptions: [],
      reationsoptions1: [],
      reationsoptions2: [],
      reationsoptions3: [],
      reationsoptions4: [],
      reationsoptions5: [],
      reationsoptions6: [],
      creditOpinion: "BBB",
      creditScore: 0.77,
      industryCode: '',
      Busines: '',
      ProductsServices: '',
      options: [

      ],
      YeraOptions: [

      ],
      Comparisvalue: "Average",
      Performancevalue: 'Mean',
      Performancevalueone: 'Mean',
      Comparisonoptions: [
        {
          value: "Average",
          label: "Average",
        },
        {
          value: "Target Company",
          label: "Target Company",
        },
      ],
      PerformanceComparis: [

      ],
      IndustryYear: "2024",
      PerformanceComparisvalue: '2024',
      PerformanceComparisvalueone: '2024',
      RatingDistributionyear: '2024',
      value2: "Number of enterprise",
      tableData: [],
      tableDatatwo: [],
      AttributeData: [],
      ProfitabilityData: [
        {
          ProfitabilityRatio: "Average",
          GrossProfitMargin: "20",
          Assets: "10",
          Equity: "20",
        },
        {
          ProfitabilityRatio: "Target Company",
          GrossProfitMargin: "20",
          Assets: "10",
          Equity: "20",
        },
      ],

      ProfitabilityData1: [],

      data: [],
      RatingDistributionData: [

      ],
      RatingDistributionDatatable: [],
      iconactive: "computer",
      iconactive1: "table",
      iconactive2: "computer",
      iconactive3: "computer",
      iconactive3: "computer",
      iconactive4: 'computer',
      inputIndex: -1,
      editcontent: "",
      // insertData
      // srcviewscompanydetailsbusinessvueDataStart

      // hangyefenxiKey
      hangyefenxiKey: 0,
      ProfitabilityDatalist: [],
      TargetValue: [],
      ProfitabilityRatio: [],
      OperatingRatio: [],
      GrowthRatio: [],
      StructuralRatio: [],
      maxValue: [],
      foundItemone: []
      // srcviewscompanydetailsbusinessvueDataEnd
    };
  },
  mounted() {
    let that = this;

    this.getratingAnalysisCurrent()
    // this.getgetMarketAnalysisQuantity()
    this.$nextTick(() => {
      // 在 DOM 更新后执行代码
      that.industryEchart();
      this.IndustryStatisticschart();

    });
  },
  computed: {

  },

  methods: {
    clickOutSide() {

      if (this.$refs.upSelect) {
        this.$refs.upSelect.visible = false

      }

    },
    clickOutSide3() {

      if (this.$refs.upSelect3) {
        this.$refs.upSelect3.visible = false

      }

    },
    clickOutSide4() {

      if (this.$refs.upSelect4) {
        this.$refs.upSelect4.visible = false

      }

    },
    clickOutSidetwo() {

      if (this.$refs.upSelecttwo) {
        this.$refs.upSelecttwo.visible = false

      }

    },
    PerformanceComparisvalueonechange() {
      this.getAttrbuteComparison()
    },
    PerformanceComparisvaluechange() {
      this.getPerformanceComparison()
    },
    reationvaluechange() {
      // this.setrise()
      this.reationvalue1 = ''
      this.reationsoptions1 = []
      this.reationsoptions2 = []
      this.reationsoptions3 = []
      this.reationsoptions4 = []
      this.reationsoptions5 = []
      this.reationsoptions6 = []
      getRatingAnalysisNumbyCondition({
        industryCode: this.industryCode,
        columns: [
          {
            "columnName": "report_year",
            "columnValue": this.reationvalue
          },
          {
            "columnName": "continent_code",
            "columnValue": ""
          },
        ]
      }).then(res => {
        if (res.code == 200) {
          this.reationsoptions1 = res.data
        }
      })
    },
    reationvaluechange1() {
      // this.setrise()
      this.reationvalue6 = ''
      this.reationsoptions2 = []
      this.reationsoptions3 = []
      this.reationsoptions4 = []
      this.reationsoptions5 = []
      this.reationsoptions6 = []
      getRatingAnalysisNumbyCondition({
        industryCode: this.industryCode,
        columns: [
          {
            "columnName": "report_year",
            "columnValue": this.reationvalue
          },
          {
            "columnName": "continent_code",
            "columnValue": this.reationvalue1
          },
          {
            "columnName": "country_code",
            "columnValue": ''
          },
        ]
      }).then(res => {
        if (res.code == 200) {
          this.reationsoptions6 = res.data
        }
      })
    },
    reationvaluechange2() {
      // this.setrise()
      this.reationvalue2 = ''
      this.reationsoptions2 = []
      this.reationsoptions3 = []
      this.reationsoptions4 = []
      this.reationsoptions5 = []
      getRatingAnalysisNumbyCondition({
        industryCode: this.industryCode,
        columns: [
          {
            "columnName": "report_year",
            "columnValue": this.reationvalue
          },
          {
            "columnName": "continent_code",
            "columnValue": this.reationvalue1
          },
          {
            "columnName": "country_code",
            "columnValue": this.reationvalue6
          },
          {
            "columnName": "industry_code",
            "columnValue": ''
          },
        ]
      }).then(res => {
        if (res.code == 200) {
          this.reationsoptions2 = res.data
        }
      })
    },
    reationvaluechange3() {
      // this.setrise()
      this.reationsoptions3 = []
      this.reationsoptions4 = []
      this.reationsoptions5 = []
      getRatingAnalysisNumbyCondition({
        industryCode: this.industryCode,
        columns: [
          {
            "columnName": "report_year",
            "columnValue": this.reationvalue
          },
          {
            "columnName": "continent_code",
            "columnValue": this.reationvalue1
          },
          {
            "columnName": "country_code",
            "columnValue": this.reationvalue6
          },
          {
            "columnName": "industry_code",
            "columnValue": this.reationvalue2
          },
          {
            "columnName": "company_years_code",
            "columnValue": ''
          },
        ]
      }).then(res => {
        if (res.code == 200) {
          this.reationsoptions3 = res.data
        }
      })
    },
    reationvaluechange4() {
      // this.setrise()
      this.reationsoptions4 = []
      this.reationsoptions5 = []
      this.reationvalue4 = ''
      getRatingAnalysisNumbyCondition({
        industryCode: this.industryCode,
        columns: [
          {
            "columnName": "report_year",
            "columnValue": this.reationvalue
          },
          {
            "columnName": "continent_code",
            "columnValue": this.reationvalue1
          },
          {
            "columnName": "country_code",
            "columnValue": this.reationvalue6
          },
          {
            "columnName": "industry_code",
            "columnValue": this.reationvalue2
          },
          {
            "columnName": "company_years_code",
            "columnValue": this.reationvalue3
          },
          {
            "columnName": "company_nature_code",
            "columnValue": ''
          },
        ]
      }).then(res => {
        if (res.code == 200) {
          this.reationsoptions4 = res.data
        }
      })
    },
    reationvaluechange5() {
      // this.setrise()

      this.reationvalue5 = ''
      getRatingAnalysisNumbyCondition({
        industryCode: this.industryCode,
        columns: [
          {
            "columnName": "report_year",
            "columnValue": this.reationvalue
          },
          {
            "columnName": "continent_code",
            "columnValue": this.reationvalue1
          },
          {
            "columnName": "country_code",
            "columnValue": this.reationvalue6
          },
          {
            "columnName": "industry_code",
            "columnValue": this.reationvalue2
          },
          {
            "columnName": "company_years_code",
            "columnValue": this.reationvalue3
          },
          {
            "columnName": "company_nature_code",
            "columnValue": this.reationvalue4
          },
          {
            "columnName": "company_size_code",
            "columnValue": ''
          },
        ]
      }).then(res => {
        if (res.code == 200) {
          this.reationsoptions5 = res.data

        }
      })
    },
    reationvaluechange6() {
      this.getgetMarketAnalysisQuantity()
    },
    getgetMarketAnalysisQuantity() {
      getgetMarketAnalysisQuantity({
        "industryCode": this.industryCode,
        "reportYear": this.reationvalue,
        "continentCode": this.reationvalue1,
        "countryCode": this.reationvalue6,
        "companyYearsCode": this.reationvalue3,
        "companyNatureCode": this.reationvalue4,
        "companySizeCode": this.reationvalue5
      }).then(res => {
        if (res.code == 200) {
          this.tableDatatwo = [
            {
              name: '',
              one: 'maximun ValueQ',
              two: 'Best vauelue',
              tree: 'Worst Value Quantity',
              fore: 'Number of Median Values'

            },
            {
              name: 'Quantity',
              one: 10,
              two: 10,
              tree: 10,
              fore: 10
            },
            {
              name: 'Ratio',
              one: '10%',
              two: '10%',
              tree: '10%',
              fore: '10%',
            },
          ]
        }
      })
    },
    // 全部数据
    getratingAnalysisCurrent() {
      getratingAnalysisCurrent({
        aaaId: this.$route.query.id3a
      }).then(res => {
        if (res.code == 200) {
          this.reationsoptions = res.data.report_year
          this.reationsoptions1 = res.data.continent
          this.reationsoptions2 = res.data.industry
          this.reationsoptions3 = res.data.company_years
          this.reationsoptions4 = res.data.company_nature
          this.reationsoptions5 = res.data.company_size
          this.reationsoptions6 = res.data.country
          this.PerformanceComparisvalueone = this.RatingDistributionyear = this.IndustryYear = this.PerformanceComparisvalue = this.PerformanceComparisvalueone = this.reationvalue = res.data.report_year[0].reportYear

          this.reationvalue1 = res.data.currentRatingAnalysisWhere.continentCode
          this.reationvalue2 = res.data.currentRatingAnalysisWhere.industryCode
          this.reationvalue3 = res.data.currentRatingAnalysisWhere.companyYearsCode
          this.industryCode = res.data.currentRatingAnalysisWhere.industryCode
          this.reationvalue4 = Number(res.data.currentRatingAnalysisWhere.companyNatureCode)
          this.reationvalue5 = Number(res.data.currentRatingAnalysisWhere.companySizeCode)
          this.companySizeCode = Number(res.data.currentRatingAnalysisWhere.companySizeCode)
          this.companyYearsCode = res.data.currentRatingAnalysisWhere.companyYearsCode
          this.reationvalue6 = res.data.currentRatingAnalysisWhere.countryCode
          // this.getgetMarketAnalysisQuantity()
          this.getPerformanceComparison()
          this.getRatingDistribution()
          this.getBlockListData()
          // this.getAttrbuteComparison()
        }
      }).catch(err => {
        this.reLoginVis = String(err).includes('403')
      })
    },
    // 获取九宫格
    getBlockListData() {
      getBlockListData({

        reportYear: this.PerformanceComparisvalue,
        industryCode: this.industryCode,
        countryCode: this.$route.query.companyCountry,
      }).then(res => {
        let manManagementSummary = [
          {
            name: "Country",
            id: "Country",
            num: res.data.country,
          },
          {
            name: "Industry",
            id: "Industry",
            num: res.data.industry,
          },
          {
            name: "Co. Size",
            id: "Co. Size",
            num: res.data.size,
          },
          {
            name: "Cash Flow Status",
            id: "Cash Flow Status",
            num: res.data.liquidity == null ? '' : res.data.liquidity,
          },
          {
            name: "No. of co. in Industry",
            id: "No. of co. in Industry",
            num: res.data.numberOfIndustry,
          },
          {
            name: "Industry Rating",
            id: "Industry Rating",
            num: res.data.industryRating,
          },
          {
            name: "Profitability",
            id: "Profitability",
            num: res.data.profitability,
          },
          {
            name: "Growth",
            id: "Growth",
            num: res.data.growth,
          },
          {
            name: "Financial Structurt",
            id: "Financial Structurt",
            num: res.data.financialStructurt,
          },
          {
            name: "Repaying",
            id: "Repaying",
            num: res.data.repaying,
          },
        ];
        this.$emit("UpdatamarkTotalList", manManagementSummary);

      })
    },
    // 切换大洲数据
    SelectPerformanceComparis() {


      const foundItem = this.ProfitabilityDatalist.find(item => item.attributeName == this.Performancevalue);
      this.TargetValue = this.ProfitabilityDatalist.find(item => item.attributeName == 'Target');
      this.TargetValue.name = 'Target Company'
      foundItem.name = 'Average'
      this.ProfitabilityRatio[0] = foundItem,
        this.ProfitabilityRatio[1] = this.TargetValue,
        // this.$nextTick(() => {
        //   // 在 DOM 更新后执行代码

        // });
        this.$nextTick(() => {
          // 在 DOM 更新后执行代码
          //   this.Attributechart();

          this.zhuzhuangtuschart();
        });



    },
    // 雷达图筛选
    SelectPerformanceComparisTwo() {
      this.foundItemone = this.AttributeData.find(item => item.attributeName == this.Performancevalueone);

      this.$nextTick(() => {
        // 在 DOM 更新后执行代码

        this.Attributechart();
      });

    },
    // 数字千分为
    formatNumber(value) {
      const number = parseFloat(value);
      if (isNaN(number)) {
        return '';
      }

      // 根据数字大小选择单位
      if (number >= 1e9) {
        return this.toThousandSeparator((number / 1e9).toFixed(2)) + 'b';
      } else if (number >= 1e6) {
        return this.toThousandSeparator((number / 1e6).toFixed(2)) + 'm';
      } else if (number >= 1e3) {
        return this.toThousandSeparator((number / 1e3).toFixed(2)) + 'k';
      } else {
        return this.toThousandSeparator(number.toString());
      }
    },
    toThousandSeparator(numStr) {
      // 使用正则表达式为数字添加千分位
      const parts = numStr.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    },

    getPerformanceComparison() {
      getPerformanceComparison({
        // aaaId:this.$route.query.id3a,
        // reportYear:PerformanceComparisvalue,
        aaaId: this.$route.query.id3a,
        reportYear: this.PerformanceComparisvalue,
        industryCode: this.industryCode,
        countryCode: this.$route.query.companyCountry,
      }).then(res => {
        if (res.code == 200 && res.data.length > 0) {
          this.PerformanceComparis = []
          res.data.map(item => {

            if (item.attributeName != 'Target Value') {
              this.PerformanceComparis.push({
                label: item.attributeName,
                value: item.attributeName,
              })
            }

          })
          this.ProfitabilityDatalist = res.data
          this.getAttrbuteComparison()
          // this.SelectPerformanceComparis()

        } else {
          let ProfitabilityRatio
          this.ProfitabilityDatalist = []
        }

      })
    },
    // Attribute Comparison

    getAttrbuteComparison() {
      // aaaId:this.$route.query.id3a,
      // reportYear:this.PerformanceComparisvalue,
      getAttrbuteComparison({
        aaaId: this.$route.query.id3a,
        reportYear: this.PerformanceComparisvalueone,
        industryCode: this.industryCode,
        countryCode: this.$route.query.companyCountry,
      }).then(res => {
        if (res.code == 200) {
          this.AttributeData = res.data.attrvalueComparisonTable
          this.maxValue = res.data.max
          this.SelectPerformanceComparisTwo()
          this.SelectPerformanceComparis()

          // this.$nextTick(() => {
          //   // 在 DOM 更新后执行代码

          //   this.Attributechart();
          // });
        } else {
          this.AttributeData = []
        }

      })
    },
    // Rating Distribution年份更改
    RatingDistributionyearchange() {
      this.getRatingDistribution()
    },
    getRatingDistribution() {
      // id3a: this.$route.query.id3a,

      let params = {
        reportYear: this.RatingDistributionyear,
        industryCode: this.industryCode,
        countryCode: this.$route.query.companyCountry,
        companySizeCode: this.companySizeCode,
        aaaId: this.$route.query.id3a,
        companyYearsCode: this.companyYearsCode,
      }
      getRatingDistribution(params).then(res => {
        if (res.code == 200) {
          this.RatingDistributionDatatable = res.data.table
          this.RatingDistributionData = res.data.chart

          this.$nextTick(() => {
            // 在 DOM 更新后执行代码
            this.riskEchart();
          });
        } else {
          this.RatingDistributionData = []
        }
      })
    },
    riskEchart() {
      const that = this;
      const option = {
        grid: {
          left: "0",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        //
        series: [
          {
            type: "gauge",
            startAngle: 180,
            endAngle: 0,

            center: ["50%", "75%"],
            radius: "146%",
            // radius: '145%',
            clockwise: true,
            show: true,
            min: 0,
            max: 1,
            splitNumber: 5,

            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 20,

                color: [
                  [
                    0.25,
                    {
                      type: "linear",

                      x: 0,

                      y: 0,

                      x2: 0,

                      y2: 1,

                      colorStops: [
                        {
                          offset: 1,
                          color: "#F56B6BFF",
                        },
                      ],

                      global: false,
                    },
                  ],

                  [
                    0.5,
                    {
                      type: "linear",

                      x: 0,

                      y: 0,

                      x2: 0,

                      y2: 1,

                      colorStops: [
                        {
                          offset: 0,
                          color: "#FFDC17FF",
                        },
                        {
                          offset: 1,
                          color: "#FA6666FF",
                        },
                      ],

                      global: false,
                    },
                  ],

                  [
                    1,
                    {
                      type: "linear",

                      x: 0,

                      y: 0,

                      x2: 0,

                      y2: 1,

                      colorStops: [
                        {
                          offset: 0,
                          color: "#FFDC17FF",
                        },
                        {
                          offset: 1,
                          color: "#55B419FF",
                        },
                      ],

                      global: false,
                    },
                  ],
                ],
              },
            },

            pointer: {
              icon: "circle",

              length: "20%",
              width: 22,
              offsetCenter: [0, "-85%"],
              itemStyle: {
                color: "auto",
              },
            },
            axisTick: {
              length: 5,
              distance: -28,
              lineStyle: {
                color: "auto",
                width: 2,
              },
            },
            // 刻度线
            splitLine: {
              show: false,
              length: 10,
              // distance:-20,
              lineStyle: {
                color: "auto",
                width: 8,
              },
            },
            axisLabel: {
              show: false,
              rotate: "tangential",
              fontWeight: "normal",
              fontSize: 0,
              distance: -62,
              width: 70,
              height: 40,
              fontFamily: "Arial",
              overflow: "truncate",
              color: [
                [
                  0.25,
                  {
                    type: "linear",

                    x: 0,

                    y: 10,

                    x2: 30,

                    y2: 11,

                    colorStops: [
                      {
                        offset: 1,
                        color: "#F56B6BFF",
                      },
                    ],

                    global: false,
                  },
                ],

                [
                  0.5,
                  {
                    type: "linear",

                    x: 0,

                    y: 0,

                    x2: 0,

                    y2: 1,

                    colorStops: [
                      {
                        offset: 0,
                        color: "#FFDC17FF",
                      },
                      {
                        offset: 1,
                        color: "#FA6666FF",
                      },
                    ],

                    global: false,
                  },
                ],

                [
                  1,
                  {
                    type: "linear",

                    x: 0,

                    y: 0,

                    x2: 0,

                    y2: 1,

                    colorStops: [
                      {
                        offset: 0,
                        color: "#FFDC17FF",
                      },
                      {
                        offset: 1,
                        color: "#55B419FF",
                      },
                    ],

                    global: false,
                  },
                ],
              ],

              formatter: function (value) {
                if (value === 1) {
                  return "Low Risk";
                } else if (value === 0.8) {
                  return "Lower Than Avg.";
                } else if (value === 0.6) {
                  return "Average";
                } else if (value === 0.4) {
                  return "Moderate";
                } else if (value === 0.2) {
                  return "Medium";
                } else if (value === 0.0) {
                  return "High Risk";
                }
                return "";
              },
            },

            title: {
              offsetCenter: [0, "20%"],
              fontSize: 15,
              color: "#ccc",
            },
            // 仪表盘数字
            detail: {
              lineHeight: 126,
              fontSize: 35,
              offsetCenter: [1, "-22"],
              valueAnimation: true,
              borderColor: "#55B419FF",
              width: 200,
              formatter: function (value) {
                var a = "d";
                if (Math.round(value * 100)) {
                  a = "A";
                }
                return that.RatingDistributionData[0]?.targetCompanyCreditOpinion;
              },
              color: "#55B419FF",
            },
            data: [
              {
                value: Number(that.RatingDistributionData[0]?.targetCompanyCreditScore) / 100,
                // name: "Credit Opinion",
              },
            ],
          },
          {
            type: "gauge",
            startAngle: 180,
            endAngle: 0,
            center: ["50%", "75%"],
            radius: "96%",
            min: 0,
            max: 1,

            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 1,
                color: [
                  [
                    0.5,
                    {
                      type: "linear",

                      x: 0,

                      y: 0,

                      x2: 0,

                      y2: 1,

                      colorStops: [
                        {
                          offset: 0,
                          color: "#FFDC17FF",
                        },
                        {
                          offset: 1,
                          color: "#FA6666FF",
                        },
                      ],

                      global: false,
                    },
                  ],

                  [
                    1,
                    {
                      type: "linear",

                      x: 0,

                      y: 0,

                      x2: 0,

                      y2: 1,

                      colorStops: [
                        {
                          offset: 0,
                          color: "#FFDC17FF",
                        },
                        {
                          offset: 1,
                          color: "#55B419FF",
                        },
                      ],

                      global: false,
                    },
                  ],
                ],
              },
            },
            pointer: {
              icon: "triangle",
              length: "12%",
              width: 20,
              offsetCenter: [0, "-105%"],
              itemStyle: {
                color: "#55B419FF",
              },
            },

            data: [
              {
                value: Number(that.RatingDistributionData[0]?.targetCompanyCreditScore) / 100,
                detail: {
                  show: false,
                },
              },
            ],
          },
        ],
      };
      const option1 = {
        grid: {
          left: "0",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        //
        series: [
          {
            type: "gauge",
            startAngle: 180,
            endAngle: 0,

            center: ["50%", "75%"],
            radius: "146%",
            // radius: '145%',
            clockwise: true,
            show: true,
            min: 0,
            max: 1,
            splitNumber: 5,

            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 20,

                color: [
                  [
                    0.25,
                    {
                      type: "linear",

                      x: 0,

                      y: 0,

                      x2: 0,

                      y2: 1,

                      colorStops: [
                        {
                          offset: 1,
                          color: "#F56B6BFF",
                        },
                      ],

                      global: false,
                    },
                  ],

                  [
                    0.5,
                    {
                      type: "linear",

                      x: 0,

                      y: 0,

                      x2: 0,

                      y2: 1,

                      colorStops: [
                        {
                          offset: 0,
                          color: "#FFDC17FF",
                        },
                        {
                          offset: 1,
                          color: "#FA6666FF",
                        },
                      ],

                      global: false,
                    },
                  ],

                  [
                    1,
                    {
                      type: "linear",

                      x: 0,

                      y: 0,

                      x2: 0,

                      y2: 1,

                      colorStops: [
                        {
                          offset: 0,
                          color: "#FFDC17FF",
                        },
                        {
                          offset: 1,
                          color: "#55B419FF",
                        },
                      ],

                      global: false,
                    },
                  ],
                ],
              },
            },

            pointer: {
              icon: "circle",

              length: "20%",
              width: 22,
              offsetCenter: [0, "-85%"],
              itemStyle: {
                color: "auto",
              },
            },
            axisTick: {
              length: 5,
              distance: -28,
              lineStyle: {
                color: "auto",
                width: 2,
              },
            },
            // 刻度线
            splitLine: {
              show: false,
              length: 10,
              // distance:-20,
              lineStyle: {
                color: "auto",
                width: 8,
              },
            },
            axisLabel: {
              show: false,
              rotate: "tangential",
              fontWeight: "normal",
              fontSize: 0,
              distance: -62,
              width: 70,
              height: 40,
              fontFamily: "Arial",
              overflow: "truncate",
              color: [
                [
                  0.25,
                  {
                    type: "linear",

                    x: 0,

                    y: 10,

                    x2: 30,

                    y2: 11,

                    colorStops: [
                      {
                        offset: 1,
                        color: "#F56B6BFF",
                      },
                    ],

                    global: false,
                  },
                ],

                [
                  0.5,
                  {
                    type: "linear",

                    x: 0,

                    y: 0,

                    x2: 0,

                    y2: 1,

                    colorStops: [
                      {
                        offset: 0,
                        color: "#FFDC17FF",
                      },
                      {
                        offset: 1,
                        color: "#FA6666FF",
                      },
                    ],

                    global: false,
                  },
                ],

                [
                  1,
                  {
                    type: "linear",

                    x: 0,

                    y: 0,

                    x2: 0,

                    y2: 1,

                    colorStops: [
                      {
                        offset: 0,
                        color: "#FFDC17FF",
                      },
                      {
                        offset: 1,
                        color: "#55B419FF",
                      },
                    ],

                    global: false,
                  },
                ],
              ],

              formatter: function (value) {
                if (value === 1) {
                  return "Low Risk";
                } else if (value === 0.8) {
                  return "Lower Than Avg.";
                } else if (value === 0.6) {
                  return "Average";
                } else if (value === 0.4) {
                  return "Moderate";
                } else if (value === 0.2) {
                  return "Medium";
                } else if (value === 0.0) {
                  return "High Risk";
                }
                return "";
              },
            },

            title: {
              offsetCenter: [0, "20%"],
              fontSize: 15,
              color: "#ccc",
            },
            // 仪表盘数字
            detail: {
              lineHeight: 126,
              fontSize: 35,
              offsetCenter: [1, "-22"],
              valueAnimation: true,

              borderColor: "#55B419FF",

              width: 200,
              formatter: function (value) {
                var a = "d";
                if (Math.round(value * 100)) {
                  a = "A";
                }
                return that.RatingDistributionData[1]?.targetCompanyCreditOpinion;
              },
              color: "#55B419FF",
            },
            data: [
              {
                value: Number(that.RatingDistributionData[1]?.targetCompanyCreditScore) / 100,
                // name: "Credit Opinion",
              },
            ],
          },
          {
            type: "gauge",
            startAngle: 180,
            endAngle: 0,
            center: ["50%", "75%"],
            radius: "96%",
            min: 0,
            max: 1,

            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 1,
                color: [
                  [
                    0.5,
                    {
                      type: "linear",

                      x: 0,

                      y: 0,

                      x2: 0,

                      y2: 1,

                      colorStops: [
                        {
                          offset: 0,
                          color: "#FFDC17FF",
                        },
                        {
                          offset: 1,
                          color: "#FA6666FF",
                        },
                      ],

                      global: false,
                    },
                  ],

                  [
                    1,
                    {
                      type: "linear",

                      x: 0,

                      y: 0,

                      x2: 0,

                      y2: 1,

                      colorStops: [
                        {
                          offset: 0,
                          color: "#FFDC17FF",
                        },
                        {
                          offset: 1,
                          color: "#55B419FF",
                        },
                      ],

                      global: false,
                    },
                  ],
                ],
              },
            },
            pointer: {
              icon: "triangle",
              length: "12%",
              width: 20,
              offsetCenter: [0, "-105%"],
              itemStyle: {
                color: "#55B419FF",
              },
            },

            data: [
              {
                value: Number(that.RatingDistributionData[1]?.targetCompanyCreditScore) / 100,
                detail: {
                  show: false,
                },
              },
            ],
          },
        ],
      };
      const option2 = {
        grid: {
          left: "0",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        //
        series: [
          {
            type: "gauge",
            startAngle: 180,
            endAngle: 0,

            center: ["50%", "75%"],
            radius: "146%",
            // radius: '145%',
            clockwise: true,
            show: true,
            min: 0,
            max: 1,
            splitNumber: 5,

            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 20,

                color: [
                  [
                    0.25,
                    {
                      type: "linear",

                      x: 0,

                      y: 0,

                      x2: 0,

                      y2: 1,

                      colorStops: [
                        {
                          offset: 1,
                          color: "#F56B6BFF",
                        },
                      ],

                      global: false,
                    },
                  ],

                  [
                    0.5,
                    {
                      type: "linear",

                      x: 0,

                      y: 0,

                      x2: 0,

                      y2: 1,

                      colorStops: [
                        {
                          offset: 0,
                          color: "#FFDC17FF",
                        },
                        {
                          offset: 1,
                          color: "#FA6666FF",
                        },
                      ],

                      global: false,
                    },
                  ],

                  [
                    1,
                    {
                      type: "linear",

                      x: 0,

                      y: 0,

                      x2: 0,

                      y2: 1,

                      colorStops: [
                        {
                          offset: 0,
                          color: "#FFDC17FF",
                        },
                        {
                          offset: 1,
                          color: "#55B419FF",
                        },
                      ],

                      global: false,
                    },
                  ],
                ],
              },
            },

            pointer: {
              icon: "circle",

              length: "20%",
              width: 22,
              offsetCenter: [0, "-85%"],
              itemStyle: {
                color: "auto",
              },
            },
            axisTick: {
              length: 5,
              distance: -28,
              lineStyle: {
                color: "auto",
                width: 2,
              },
            },
            // 刻度线
            splitLine: {
              show: false,
              length: 10,
              // distance:-20,
              lineStyle: {
                color: "auto",
                width: 8,
              },
            },
            axisLabel: {
              show: false,
              rotate: "tangential",
              fontWeight: "normal",
              fontSize: 0,
              distance: -62,
              width: 70,
              height: 40,
              fontFamily: "Arial",
              overflow: "truncate",
              color: [
                [
                  0.25,
                  {
                    type: "linear",

                    x: 0,

                    y: 10,

                    x2: 30,

                    y2: 11,

                    colorStops: [
                      {
                        offset: 1,
                        color: "#F56B6BFF",
                      },
                    ],

                    global: false,
                  },
                ],

                [
                  0.5,
                  {
                    type: "linear",

                    x: 0,

                    y: 0,

                    x2: 0,

                    y2: 1,

                    colorStops: [
                      {
                        offset: 0,
                        color: "#FFDC17FF",
                      },
                      {
                        offset: 1,
                        color: "#FA6666FF",
                      },
                    ],

                    global: false,
                  },
                ],

                [
                  1,
                  {
                    type: "linear",

                    x: 0,

                    y: 0,

                    x2: 0,

                    y2: 1,

                    colorStops: [
                      {
                        offset: 0,
                        color: "#FFDC17FF",
                      },
                      {
                        offset: 1,
                        color: "#55B419FF",
                      },
                    ],

                    global: false,
                  },
                ],
              ],

              formatter: function (value) {
                if (value === 1) {
                  return "Low Risk";
                } else if (value === 0.8) {
                  return "Lower Than Avg.";
                } else if (value === 0.6) {
                  return "Average";
                } else if (value === 0.4) {
                  return "Moderate";
                } else if (value === 0.2) {
                  return "Medium";
                } else if (value === 0.0) {
                  return "High Risk";
                }
                return "";
              },
            },

            title: {
              offsetCenter: [0, "20%"],
              fontSize: 15,
              color: "#ccc",
            },
            // 仪表盘数字
            detail: {
              lineHeight: 126,
              fontSize: 35,
              offsetCenter: [1, "-22"],
              valueAnimation: true,

              borderColor: "#55B419FF",

              width: 200,
              formatter: function (value) {
                var a = "d";
                if (Math.round(value * 100)) {
                  a = "A";
                }
                return that.RatingDistributionData[2]?.targetCompanyCreditOpinion;
              },
              color: "#55B419FF",
            },
            data: [
              {
                value: Number(that.RatingDistributionData[2]?.targetCompanyCreditScore) / 100,
                // name: "Credit Opinion",
              },
            ],
          },
          {
            type: "gauge",
            startAngle: 180,
            endAngle: 0,
            center: ["50%", "75%"],
            radius: "96%",
            min: 0,
            max: 1,

            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 1,
                color: [
                  [
                    0.5,
                    {
                      type: "linear",

                      x: 0,

                      y: 0,

                      x2: 0,

                      y2: 1,

                      colorStops: [
                        {
                          offset: 0,
                          color: "#FFDC17FF",
                        },
                        {
                          offset: 1,
                          color: "#FA6666FF",
                        },
                      ],

                      global: false,
                    },
                  ],

                  [
                    1,
                    {
                      type: "linear",

                      x: 0,

                      y: 0,

                      x2: 0,

                      y2: 1,

                      colorStops: [
                        {
                          offset: 0,
                          color: "#FFDC17FF",
                        },
                        {
                          offset: 1,
                          color: "#55B419FF",
                        },
                      ],

                      global: false,
                    },
                  ],
                ],
              },
            },
            pointer: {
              icon: "triangle",
              length: "12%",
              width: 20,
              offsetCenter: [0, "-105%"],
              itemStyle: {
                color: "#55B419FF",
              },
            },

            data: [
              {
                value: Number(that.RatingDistributionData[2]?.targetCompanyCreditScore) / 100,
                detail: {
                  show: false,
                },
              },
            ],
          },
        ],
      };

      this.riskChart = this.$echarts.init(this.$refs.riskRef);
      this.riskChart1 = this.$echarts.init(this.$refs.riskRef1);
      this.riskChart2 = this.$echarts.init(this.$refs.riskRef2);
      // this.riskChart.dispose()
      // this.riskChart1.dispose()
      // this.riskChart2.dispose()
      this.riskChart.setOption(option);
      this.riskChart1.setOption(option1);
      this.riskChart2.setOption(option2);
    },
    zhuhuangcehartsi() {
      this.iconactive = 'computer'


      this.$nextTick(() => {
        // 在 DOM 更新后执行代码

        this.zhuzhuangtuschart();
      });
    },
    getProgress(item) {
      try {
        let tempData = item.rate.replace('%', '');
        if (tempData > 0 && tempData <= 100) {
          return Number(tempData);
        } else {
          return 0;
        }
      } catch (error) {
        return 0;
      }
    },
    CancelAdd() {
      this.inputIndex = -1;
      this.tableData.pop();
    },
    edit(content) {
      this.editcontent = content;

      //   this.tableData.push({ ...this.tableData[index-1] });
    },
    // insertMethod
    // srcviewscompanydetailsbusinessvueMethodStart
    // returnPage

    returnPage() {
      history.go(-1);
    }, // reShowPopver1
    reShowPopver1() {
      debugger;
      this.$refs.myPopover.doClose();

      this.$nextTick(() => {
        this.$refs.myPopover.doShow();
      });
    },
    cellClassName({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return "highlight-cell"; // 设置你想要的背景色
      }
    },
    addInput(index) {
      this.inputIndex = index;
      this.tableData.push({ ...this.tableData[index - 1] });
    },
    industryEchart() {
      let that = this;
      this.echartsBox = this.$echarts.init(this.$refs.SupplyChainEchart);
      //   this.$echarts.registerTransform(ecStat.transform.clustering)

      const data = [
        [
          [28604, 77, 17096869, '2011',],
          [31163, 77.4, 27662440, '2012',],
          [1516, 68, 1154605773, '2012',],

        ],
        [
          [44056, 81.8, 23968973, '2012',],
          [43294, 81.7, 35939927, '2012',],
          [13334, 76.9, 1376048943, '2012',],
          [21291, 78.5, 11389562, '2012',],

        ]
      ];
      let option = {


        legend: {
          right: '1%',
          top: '1%',
          data: ['pro', 'inc']
        },
        grid: {
          left: '5%',
          right: '3%',
          top: '5%',
          bottom: '4%'
        },
        xAxis: {
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        yAxis: {
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          },
          scale: true
        },
        series: [
          {
            name: 'pro',
            data: data[0],
            type: 'scatter',
            symbolSize: function (data) {
              return Math.sqrt(data[2]) / 5e2;
            },
            emphasis: {
              focus: 'series',
              label: {
                show: true,
                formatter: function (param) {
                  return param.data[3];
                },
                position: 'top'
              }
            },
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(120, 36, 50, 0.5)',
              shadowOffsetY: 5,

            }
          },
          {
            name: 'inc',
            data: data[1],
            type: 'scatter',
            symbolSize: function (data) {
              return Math.sqrt(data[2]) / 5e2;
            },
            emphasis: {
              focus: 'series',
              label: {
                show: true,
                formatter: function (param) {
                  return param.data[3];
                },
                position: 'top'
              }
            },
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(25, 100, 150, 0.5)',
              shadowOffsetY: 5,

            }
          }
        ]
      };
      this.echartsBox.setOption(option);
    },
    IndustryStatisticschart() {
      let that = this;
      this.echartsBox = this.$echarts.init(this.$refs.IndustryStatisticschart);
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [
              "Insurance Services",
              "Less Than 3 Years",
              "Large Companies",
              "China",
              "BB+",
            ],
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Direct",
            type: "bar",
            barWidth: "60%",
            data: [10000, 5000, 5000, 5000, 5000, 5000, 5000],
            barWidth: "80px", // 柱子宽度
            itemStyle: {
              color: "#1290C9", // 柱子颜色
            },
          },
        ],
      };
      this.echartsBox.setOption(option);
    },
    zhuzhuangtuschart() {
      this.echartsBox = this.$echarts.init(this.$refs.zhuzhuangtuschart);
      this.echartsBox1 = this.$echarts.init(this.$refs.zhuzhuangtuschart1);
      this.echartsBox2 = this.$echarts.init(this.$refs.zhuzhuangtuschart2);
      this.echartsBox3 = this.$echarts.init(this.$refs.zhuzhuangtuschart3);
      let option = {
        title: {
          //标题组件
          show: "true",
          text: "Profitability Ratios",
          extstyle: {
            //主标题文本样式
            color: "blue",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: "5%", //距离dom间距
          right: "4%",
          top: "15%",
          bottom: "1%",
        },

        xAxis: [
          {
            type: "value",
            boundaryGap: true,
            axisPointer: {
              type: "shadow",
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "category",
            max: 2,
            splitLine: { show: false },
            axisLabelGap: 1,

            gap: 1,
            inverse: true,
            axisLabel: {
              verticalAlign: "bottom",
              align: "left",
              padding: [0, 10, 35, 10],
              textStyle: {
                fontSize: 12,
                color: "black",
              },
            },
            nameGap: 2,
            // 纵坐标数据
            data: [
              "Gross margin  (%)",
              "Return on assets  (%)",
              "Return on equity  (%)",
            ],
            yAxisIndex: 0,
            // 横坐标 分割线等取消显示
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
          },
          {
            type: "category",
            inverse: true,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              inside: false,
              verticalAlign: "bottom",
              // 位置 上右下左
              padding: [0, 10, 10, -40],
              //   lineHeight: "40",
              textStyle: {
                fontSize: 12,
                color: "black",
                fontFamily: "SourceHanSansCN-Regular",
              },
            },
            // 统计的总量 用纵坐标模拟
            data: [1, 1, 1],
          },
        ],
        series: [
          {
            name: "Average Value",
            type: "bar",
            // 宽度
            barWidth: "20",
            // 堆叠
            stack: "总量1",
            showBackground: true,
            // 全部背景

            data: [this.ProfitabilityRatio[0].grossProfitMargin, this.ProfitabilityRatio[0].returnOnTotalAssets, this.ProfitabilityRatio[0].returnOnEquity],
            barCategoryGap: "0%",
            itemStyle: {
              color: "#FF7600", // 柱子颜色
            },
          },
          {
            name: "Target Company",
            type: "bar",
            // 宽度
            barWidth: "20",
            // 堆叠
            stack: "总量2",
            showBackground: true,
            // 全部背景

            barGap: "1%",
            barCategoryGap: "0%",
            data: [this.ProfitabilityRatio[1].grossProfitMargin, this.ProfitabilityRatio[1].returnOnTotalAssets, this.ProfitabilityRatio[1].returnOnEquity],
            itemStyle: {
              color: "#1290C9", // 柱子颜色
            },
          },
        ],
      };
      let option1 = {
        title: {
          //标题组件
          show: "true",
          text: "Growth Ratios",
          extstyle: {
            //主标题文本样式
            color: "blue",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: "5%", //距离dom间距
          right: "4%",
          top: "15%",
          bottom: "1%",
        },

        xAxis: [
          {
            type: "value",
            boundaryGap: true,
            axisPointer: {
              type: "shadow",
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "category",
            max: 2,
            splitLine: { show: false },
            axisLabelGap: 1,

            gap: 1,
            inverse: true,
            axisLabel: {
              verticalAlign: "bottom",
              align: "left",
              padding: [0, 10, 35, 10],
              textStyle: {
                fontSize: 12,
                color: "black",
              },
            },
            nameGap: 2,
            // 纵坐标数据
            data: [
              "Total assets growth rate  (%)",
              "Sales growth rate  (%)",
              "Net profit growth rate  (%)",
            ],
            yAxisIndex: 0,
            // 横坐标 分割线等取消显示
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
          },
          {
            type: "category",
            inverse: true,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              inside: false,
              verticalAlign: "bottom",
              // 位置 上右下左
              padding: [0, 10, 10, -40],
              //   lineHeight: "40",
              textStyle: {
                fontSize: 12,
                color: "black",
                fontFamily: "SourceHanSansCN-Regular",
              },
            },
            // 统计的总量 用纵坐标模拟
            data: [1, 1, 1],
          },
        ],
        series: [
          {
            name: "Average Value",
            type: "bar",
            // 宽度
            barWidth: "20",
            // 堆叠
            stack: "总量1",
            showBackground: true,
            // 全部背景

            data: [this.ProfitabilityRatio[0].totalAssetGrowthRate, this.ProfitabilityRatio[0].revenueGrowthRate, this.ProfitabilityRatio[0].operatingProfitGrowthRate == null ? 0 : this.ProfitabilityRatio[0].operatingProfitGrowthRate,],
            barCategoryGap: "0%",
            itemStyle: {
              color: "#FF7600", // 柱子颜色
            },
          },
          {
            name: "Target Company",
            type: "bar",
            // 宽度
            barWidth: "20",
            // 堆叠
            stack: "总量2",
            showBackground: true,
            // 全部背景

            barGap: "1%",
            barCategoryGap: "0%",
            data: [this.ProfitabilityRatio[1].totalAssetGrowthRate, this.ProfitabilityRatio[1].revenueGrowthRate, this.ProfitabilityRatio[1].operatingProfitGrowthRate == null ? 0 : this.ProfitabilityRatio[1].operatingProfitGrowthRate,],
            itemStyle: {
              color: "#1290C9", // 柱子颜色
            },
          },
        ],
      };
      let option2 = {
        title: {
          //标题组件
          show: "true",
          text: "Operating Ratios",
          extstyle: {
            //主标题文本样式
            color: "blue",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: "5%", //距离dom间距
          right: "4%",
          top: "15%",
          bottom: "1%",
        },
        // legend:{
        //       data:['Average Value','Target Company'],
        //       textStyle:{
        //           color:'black',
        //       },
        //       x:'right',
        //       y: '1%',
        //   },
        xAxis: [
          {
            type: "value",
            boundaryGap: true,
            axisPointer: {
              type: "shadow",
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "category",
            max: 3,
            splitLine: { show: false },
            axisLabelGap: 1,

            gap: 1,
            inverse: true,
            axisLabel: {
              verticalAlign: "bottom",
              align: "left",
              padding: [0, 10, 35, 10],
              textStyle: {
                fontSize: 12,
                color: "black",
              },
            },
            nameGap: 2,
            // 纵坐标数据
            data: [
              "Stock turnover  (x)",
              "Assets turnover  (x)",
              "Current asset turnover rate",
              "Collection period  (days)",
            ],
            yAxisIndex: 0,
            // 横坐标 分割线等取消显示
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
          },
          {
            type: "category",
            inverse: true,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              inside: false,
              verticalAlign: "bottom",
              // 位置 上右下左
              padding: [0, 10, 10, -40],
              //   lineHeight: "40",
              textStyle: {
                fontSize: 12,
                color: "black",
                fontFamily: "SourceHanSansCN-Regular",
              },
            },
            // 统计的总量 用纵坐标模拟
            data: [1, 1, 1, 1],
          },
        ],
        series: [
          {
            name: "Average Value",
            type: "bar",
            // 宽度
            barWidth: "20",
            // 堆叠
            stack: "总量1",
            showBackground: true,
            // 全部背景

            data: [this.ProfitabilityRatio[0].inventoryTurnoverRate == null ? 0 : this.ProfitabilityRatio[0].inventoryTurnoverRate, this.ProfitabilityRatio[0].totalAssetTurnoverRate, this.ProfitabilityRatio[0].currentAssetTurnover = null ? 0 : this.ProfitabilityRatio[0].currentAssetTurnover, this.ProfitabilityRatio[0].accountsReceivableTurnoverRate = null ? 0 : this.ProfitabilityRatio[0].accountsReceivableTurnoverRate],
            barCategoryGap: "0%",
            itemStyle: {
              color: "#FF7600", // 柱子颜色
            },
          },
          {
            name: "Target Company",
            type: "bar",
            // 宽度
            barWidth: "20",
            // 堆叠
            stack: "总量2",
            showBackground: true,
            // 全部背景

            barGap: "1%",
            barCategoryGap: "0%",
            data: [this.ProfitabilityRatio[1].inventoryTurnoverRate == null ? 0 : this.ProfitabilityRatio[1].inventoryTurnoverRate, this.ProfitabilityRatio[1].totalAssetTurnoverRate, this.ProfitabilityRatio[1].currentAssetTurnover = null ? 0 : this.ProfitabilityRatio[1].currentAssetTurnover, this.ProfitabilityRatio[1].accountsReceivableTurnoverRate = null ? 0 : this.ProfitabilityRatio[1].accountsReceivableTurnoverRate],
            itemStyle: {
              color: "#1290C9", // 柱子颜色
            },
          },
        ],
      };
      let option3 = {
        title: {
          //标题组件
          show: "true",
          text: "Structural Ratios",
          extstyle: {
            //主标题文本样式
            color: "blue",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: "5%", //距离dom间距
          right: "4%",
          top: "15%",
          bottom: "1%",
        },
        // legend:{
        //       data:['Average Value','Target Company'],
        //       textStyle:{
        //           color:'black',
        //       },
        //       x:'right',
        //       y: '1%',
        //   },
        xAxis: [
          {
            type: "value",
            boundaryGap: true,
            axisPointer: {
              type: "shadow",
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "category",
            max: 2,
            splitLine: { show: false },
            axisLabelGap: 1,

            gap: 1,
            inverse: true,
            axisLabel: {
              verticalAlign: "bottom",
              align: "left",
              padding: [0, 10, 35, 10],
              textStyle: {
                fontSize: 12,
                color: "black",
              },
            },
            nameGap: 2,
            // 纵坐标数据
            data: [
              "Debt  ratio  (%)",
              "Quick ratio  (x)",
              "Current ratio  (x)",
            ],
            yAxisIndex: 0,
            // 横坐标 分割线等取消显示
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
          },
          {
            type: "category",
            inverse: true,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              inside: false,
              verticalAlign: "bottom",
              // 位置 上右下左
              padding: [0, 10, 10, -40],
              //   lineHeight: "40",
              textStyle: {
                fontSize: 12,
                color: "black",
                fontFamily: "SourceHanSansCN-Regular",
              },
            },
            // 统计的总量 用纵坐标模拟
            data: [1, 1, 1],
          },
        ],
        series: [
          {
            name: "Average Value",
            type: "bar",
            // 宽度
            barWidth: "20",
            // 堆叠
            stack: "总量1",
            showBackground: true,
            // 全部背景

            data: [this.ProfitabilityRatio[0].assetLiabilityRatio == null ? 0 : this.ProfitabilityRatio[0].assetLiabilityRatio, this.ProfitabilityRatio[0].quickRatio == null ? 0 : this.ProfitabilityRatio[0].quickRatio, this.ProfitabilityRatio[0].currentRatio == null ? 0 : this.ProfitabilityRatio[0].currentRatio],
            barCategoryGap: "0%",
            itemStyle: {
              color: "#FF7600", // 柱子颜色
            },
          },
          {
            name: "Target Company",
            type: "bar",
            // 宽度
            barWidth: "20",
            // 堆叠
            stack: "总量2",
            showBackground: true,
            // 全部背景

            barGap: "1%",
            barCategoryGap: "0%",
            data: [this.ProfitabilityRatio[1].assetLiabilityRatio == null ? 0 : this.ProfitabilityRatio[1].assetLiabilityRatio, this.ProfitabilityRatio[1].quickRatio == null ? 0 : this.ProfitabilityRatio[1].quickRatio, this.ProfitabilityRatio[1].currentRatio == null ? 0 : this.ProfitabilityRatio[1].currentRatio],
            itemStyle: {
              color: "#1290C9", // 柱子颜色
            },
          },
        ],
      };
      this.echartsBox.setOption(option);
      this.echartsBox1.setOption(option2);
      this.echartsBox2.setOption(option1);
      this.echartsBox3.setOption(option3);
    },
    // 雷达图
    Attributechart() {
      let that = this;
      let targetdata = {}
      this.AttributeData.map(item => {
        if (item.attributeName == "Target Value") {
          targetdata = item
        }
      })

    
      this.echartsBox = this.$echarts.init(this.$refs.Attributechart);

      let option = {
        grid: {
          left: "0%",   // 设置左侧边距为0
          right: "0%",  // 设置右侧边距为0
          top: "0%",     // 设置上边距为0
          bottom: "0%",  // 设置下边距为0
          containLabel: true,
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          data: [that.Performancevalueone, "Target Company"],
          right: "0%",
          top: "3%",
        },
        radar: {
          // shape: 'circle',
          indicator: [
            { name: "Revenue", max: that.maxValue.turnover, },
            { name: "Employees", max: that.maxValue.employee, },
            { name: "Net Earnings", max: that.maxValue.netProfit, },
            { name: "Total Assets", max: that.maxValue.totalAssets, },
            { name: "Net Assets", max: that.maxValue.netAssets, },
          ],
        },
        series: [
          {
            name: "Budget vs spending",
            type: "radar",
            data: [
              // [  this.foundItemone.turnover,this.foundItemone.employee,    this.foundItemone.netProfit, this.foundItemone.totalAssets,  this.foundItemone.netAssets]
              {
                value: [this.foundItemone.turnover, this.foundItemone.employee, this.foundItemone.netProfit, this.foundItemone.totalAssets, this.foundItemone.netAssets],
                name: that.Performancevalueone,
              },
              {
                value: [targetdata?.turnover, targetdata?.employee, targetdata?.netProfit, targetdata?.totalAssets, targetdata?.netAssets],
                name: "Target Company",
              },
            ],
          },
        ],
      };
      this.echartsBox.setOption(option);
    },
    // srcviewscompanydetailsbusinessvueMethodEnd
  },

};
</script>
<style scoped>
.ua915f {
  margin-right: 0em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-top: 0em;
  margin-left: 0em;
  margin-bottom: 0em;
}

.operations-head {
  font-size: 2.142857142857143rem;
  font-family: 'DMSans Bold';
  color: #1a2332;
  text-align: left;
  font-style: normal;
  display: flex;
  justify-content: space-between;
  height: 2.857142857142857rem;
  align-items: center;
  margin-bottom: 1.4285714285714286rem;
}

.operations-content {
  background: #ffffff;
}

.operations-box {
  display: flex;
  justify-content: space-between;
}

/deep/.el-descriptions__title {
  font-size: 2.142857142857143rem;
  font-family: 'DMSans Bold';
  color: #1a2332;
  text-align: left;
  font-style: normal;
}

/deep/ .el-descriptions-item__label {
  display: block !important;
  margin-right: 2.142857142857143rem !important;
  /* padding-bottom: 2.142857142857143rem !important; */
}

.operations-label-title {
  width: 14.285714285714286rem;
  font-size: 1.4285714285714286rem;
  font-family: 'DMSans Bold';
  color: #1a2332;
  line-height: 1.8571428571428572rem;
}

.operations-label-text {
  font-size: 1.4285714285714286rem;
  font-family: "ArialMT";
  color: #29445d;
  line-height: 1.8571428571428572rem;
  width: 100%;
}

/deep/ .el-textarea__inner {
  font-size: 1.4285714285714286rem;
  color: #29445d;
}

.ViewMore {
  line-height: 1.8571428571428572rem;
  text-align: left;
  font-style: normal;
  text-decoration-line: underline;
  margin-left: 2.142857142857143rem;
}

.operations-box-left {
  /* margin-right: 4.285714285714286rem; */
  /* width: 45%; */
  width: 100%;
  background: #ffffff;
  box-shadow: 0rem 0.14285714285714285rem 1rem 0rem rgba(0, 0, 0, 0.12);
  border-radius: 0.2857142857142857rem;
  padding: 2.142857142857143rem 2.857142857142857rem;
  height: 100%;
}

.jiange {
  color: #cccccc;
  margin: 0 1.4285714285714286rem;
  font-size: 1.4285714285714286rem;
  /* font-size: 1.25em; */
}

.contclass {
  font-size: 1.4285714285714286rem;
  color: #1290c9;
  font-style: normal;
  height: 3.5714285714285716rem;
  background: #f5f5f5;
  border-radius: 1.7857142857142858rem;
  display: flex;
  align-items: center;
  padding: 0.21428571428571427rem;
}

.last-colmun {
  padding: 2rem 0rem;
  border-bottom: 0.07142857142857142rem solid #ebeef5;
}

.dingwei {
  position: absolute;
  top: -2.142857142857143rem;
  right: 0rem;
}

/deep/ .el-descriptions-item__cell {
  padding-bottom: 2.142857142857143rem !important;
}

.icon {
  margin: 0 1.4285714285714286rem;
}

/deep/ .highlight-cell {
  background-color: #f2f5f9 !important;
}

/deep/ .el-table .cell {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  line-height: 2.142857142857143rem;
  word-break: keep-all;
}

/deep/ .el-table th.el-table__cell>.cell {
  font-family: 'DMSans Bold';
}

/deep/ .el-table {
  margin-top: 1.4285714285714286rem;

}

.fivetable /deep/ .el-table {
  margin-top: 1.4285714285714286rem;
}

.operations-center-right /deep/ .el-table {
  margin-top: 1.4285714285714286rem;
  height: 97%;

}

.operations-center-right .table-content {
  line-height: 2.142857142857143rem !important;

}

/deep/ .el-table th.el-table__cell {
  background-color: #f2f5f9 !important;
}

.table-content {
  /* height: 4rem !important;
  line-height: 4rem; */
}

.operations-box-right {
  /* width:42.857142857142854rem; */
  height: 100%;
}

.svgbg {
  background-color: #ffffff;
}

.svgclass {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 3.142857142857143rem;
  border-radius: 1.5714285714285714rem;
}

.operations-headtwo {
  justify-content: end;
}

.flex_center_between_box {
  display: flex;
  justify-content: space-between;
}

.line_step {
  /* margin-bottom: 2.142857142857143rem; */
  margin-bottom: 2rem;
  font-size: 1.1428571428571428rem;
  color: #8497AB;
}

.line_step .line_title {
  display: inline-block;
  /* width: 9.857142857142858rem; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.2857142857142858rem;

  text-decoration-line: underline;
}

.blue_num {
  display: block;
  text-align: right;
  color: #8497AB;
}

.blue_numon {
  color: #1290C9;
}

.line_step /deep/.el-progress {
  margin-top: 0.7142857142857143rem;
}

.TopPorts {
  font-family: 'DMSans Bold';
  font-weight: normal;
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  line-height: 1.6428571428571428rem;
  /* height: 3.142857142857143rem; */
  margin-bottom: 1.4285714285714286rem;
}

.operations-center-left {
  padding: 1.4285714285714286rem;
  background-color: #f5f5f5;
  margin-top: 1.4285714285714286rem;
  padding-bottom: 0rem;
  overflow: scroll;
  width: 0;
  flex: auto;
}

.operations-center-item {
  width: 11.428571428571429rem;
  /* margin: 0 1.2857142857142858rem; */
}

.Attribute-left .table-content {
  display: block;
  padding: 4.071428571428571rem 0rem;
  height: auto !important;
}

.flex-warp {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1.4285714285714286rem;
  width: 100%;
}

.flex-warp-item {
  /* width: 49%; */
  background-color: #f5f5f5;
}

.echarts {
  width: 30.714285714285715rem;
  height: 17.857142857142858rem;
  margin: auto;
}

.table_box {
  position: relative;
  margin-top: 2.142857142857143rem;
}

.aaa {
  font-size: 0.7142857142857143rem;
  color: #fa6666;
  position: absolute;
  left: 10%;
  top: 14.285714285714286rem;
}

.bbb {
  font-size: 0.7142857142857143rem;
  color: #fa755c;
  position: absolute;
  left: 8%;
  top: 6.071428571428571rem;
}

.ccc {
  font-size: 0.7142857142857143rem;
  color: #fc9249;
  position: absolute;
  left: 20%;
  top: 0.5714285714285714rem;
}

.ddd {
  font-size: 0.7142857142857143rem;
  color: #ffd81a;
  position: absolute;
  left: 70%;
  top: 0.5714285714285714rem;
}

.fff {
  font-size: 0.7142857142857143rem;
  color: #aec918;
  position: absolute;
  width: 3.642857142857143rem !important;
  height: 2.142857142857143rem;
  top: 6.071428571428571rem;
  left: 83%;
}

.ggg {
  font-size: 0.7142857142857143rem;
  color: #63b819;
  position: absolute;
  left: 81%;

  top: 14.285714285714286rem;
}

.hhh {
  font-size: 1.4285714285714286rem;
  color: #8497AB;
  position: absolute;
  left: 35%;
  top: 14.285714285714286rem;
}

.jjj {
  font-size: 1.4285714285714286rem;
  color: #1A2332;
  position: absolute;
  left: 36%;
  top: 16.428571428571427rem;
}

.Create-titel {
  font-size: 1.4285714285714286rem;
  color: #1A2332;
  line-height: 1.6428571428571428rem;
  text-align: center;

}

.table {
  text-align: center;
  margin-top: 1.4285714285714286rem;
  background-color: #F5F5F5;
  padding-top: 2.142857142857143rem;
  margin: auto;
  height: 25.714285714285715rem;
}

.el-table .el-table__cell {
  padding: 0.8571428571428571rem 0;
}

.ecahrtstu {
  width: 32.5%;
}

.ecahrtstu-li {
  width: 49%;
  background-color: #F5F5F5;
  text-align: center;

  padding: 2.142857142857143rem 0rem;
  margin-top: 0.7142857142857143rem;
}

.ecahrtstu-title {
  display: block;
  font-size: 3.5714285714285716rem;
  color: #93C623;
  line-height: 4.142857142857143rem;
}

.ecahrtst-text {
  font-size: 1.4285714285714286rem;
  color: #1A2332;
  line-height: 1.6428571428571428rem;
  margin-top: 1.1428571428571428rem;
}

/deep/ .el-table .cell {
  padding-left: 1.0714285714285714rem !important;
  padding-right: 1.0714285714285714rem !important;
}

.TopPorts /deep/ .el-select .el-input__inner {
  border: none !important;
  background-color: transparent !important;
  padding-left: 0rem;
}

/deep/ .el-table .el-table__cell {
  padding: 0.35714285714285715rem 0;
}

@media screen and (max-width: 1441px) {
  /deep/ .el-table .el-table__cell {
    padding: 0;
  }
}
</style>